import { styled } from '@mui/system';
import { Datagrid as RaDataGrid } from 'react-admin';

const Datagrid = styled(RaDataGrid)({
    "& .RaDatagrid-headerCell": {
        backgroundColor: "rgb(143, 205, 242)",
        fontWeight: 'bold'
    },
    "& .RaDatagrid-rowEven": {
      backgroundColor: "rgb(247, 247, 247)"
    }
});

export default Datagrid;