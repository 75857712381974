import { getResourcePath } from "admin/pages-factory";
import {
  required,
  SimpleForm,
  TextInput,
  minLength,
  maxLength,
  Create,
  useDataProvider,
  useRedirect} from "react-admin";

const GroupsTitle = ({ record }: any) => {
  return <span>Create new group</span>;
};

interface FormFields {
  name?: string;
}


const formValidator = (values: FormFields) => {
  const errors: FormFields = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  return errors;
};


const GroupsCreate = (props: any) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const postSubmit = (data: FormFields) => {
    dataProvider.create(getResourcePath('groups', 'accounts'), { data })
      .then(() => {redirect(getResourcePath('groups', 'accounts'))});
  };

  return (
    <Create title={<GroupsTitle />} {...props} redirect={getResourcePath('groups', 'accounts')}>
      <SimpleForm 
            validate={formValidator}
            onSubmit={postSubmit}>
      <TextInput
                 source="name"
                 validate={[required(), minLength(3), maxLength(100)]}
                 autoFocus />

      </SimpleForm>
    </Create>
  );
} 

export default GroupsCreate;