/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import {RaRecord, useRecordContext } from 'react-admin';
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material"; 
import AddPermission from './add-permission';
import usePermissionsAtGroups from './hooks/permissions';
  

const PermissionAtGroup = (props: any) => {

const record = props.record as RaRecord;
const {
    permissions,
    total,
    perPage,
    setPage,
    setPerPage,
    deletePermission,
    addPermission} = usePermissionsAtGroups({groupId: record.id});

const columns = [
  {
    field: "name",
    headerName: "Permission Name",
    flex: 1,
    minWidth: 250,
    sortable: true
  },
  {
    field: "permission-record-action",
    headerName: "Action",
    minWidth: 250,
    width: 100,
    sortable: false,
    renderCell: (params: any) => {
      return (
              <Button 
                  onClick={() => {
                      deletePermission(params.id);
                    }}>
                      Delete
              </Button>
    )}
  }
];

return (
        <>
          <DataGrid
              rows={permissions}
              columns={columns}
              rowCount={total}
              pageSize={perPage}
              onPageChange = {newPage => setPage(newPage)}
              onPageSizeChange={newPageSize => setPerPage(newPageSize)}
              autoHeight/>
          <AddPermission exclude={permissions}
                    record={record}
                    onAdd={selected => {
                      if (selected) {
                        addPermission(selected)
                      }
                    }}/>
        </>);
}

export default PermissionAtGroup;
