import { Resource, ResourceElement } from 'react-admin';
import list from './history-list';
import CompareIcon from '@mui/icons-material/Compare';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Список загруженных файлов в истории сервиса сравнения
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const CompareHistoryPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку)
  return permissions
              ? <Resource name= {getResourcePath('compare_history', 'compare')}
                          list={list}
                          icon={CompareIcon}
                          options={{label: "History"}} />
              : null;
}


export default CompareHistoryPage;
