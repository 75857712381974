export const knownOperations: Array<string> = [
  'languages',
  'tokens',
  'keywords',
  'lemmas',
  'sentences',
  'parts-of-speech',
  'entities',
  'sentiments',
  'facts',
  'chunks',
  'topics',
  'dependency-trees',
  'constituency-trees',
  'grammar-mistakes'
];