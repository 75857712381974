import { useStoreContext } from "admin/store-context";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import {  Edit,
          minLength,
          maxLength,
          required,
          SimpleForm,
          TextInput,
          BooleanInput,
          AutocompleteArrayInput, 
          RaRecord,
          ValidateForm,
          useRecordContext} from "react-admin";
import { capitalizeFirstLetter } from "utilities/algorithm/string";
import { knownOperations } from "./operations";
import mainStore from "admin/store-instance";

type RaProps = Omit<RaRecord, 'id'>;

const Title = observer(() => {
  const record = useRecordContext();
  const {services} = mainStore;
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Language/${record?.language}`, [services.activeId, record?.language, record]);
  return <span>Edit {label}</span>;
});

interface RetFormValidator {
  language?: string;
  limitedCovarage?: boolean;
  operations?: Array<string>;
  abbreviation?: string;
}

const EditItem: React.FC<RaProps> = props => {

  const formValidator: ValidateForm = values => {
    const errors: RetFormValidator = {};
    return errors;
  };

  useRecordContext();  

  return (
  <Edit title={<Title />} {...props}>
      <SimpleForm validate={formValidator}>
        <TextInput disabled source="language" />
        <TextInput 
                 source="abbreviation"
                 label = 'Abbreviation'
                 validate={[minLength(3), maxLength(50), required()]}/>
        <BooleanInput source="limitedCovarage" label = 'Limited'/>
        <AutocompleteArrayInput 
                  source='operations'
                  label = 'Operations'
                  choices={knownOperations.map(operation => ({id: operation, name: operation}))}/>

      </SimpleForm>
  </Edit>)
}

export default EditItem;