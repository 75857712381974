import { DateInput } from 'react-admin'

interface Props {
  source: string;
  label: string;
}

const DateBetweenInput: React.FC<Props> = props => {
    const { source, label } = props
    return (
      <>
        <DateInput
          key={'start'}
          helperText=''
          source={`${source}.gt`}
          label={`${label} from`}
          autoFocus
        />
        &nbsp;
        <DateInput
          key={'end'}
          helperText=''
          source={`${source}.lte`}
          label={`${label} to`}
        />
      </>
    );
}

export default DateBetweenInput