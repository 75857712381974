import {
  Edit,
  minLength,
  maxLength,
  required,
  SimpleForm,
  TextInput,
  SelectInput, BooleanInput, RaRecord, ValidateForm
} from "react-admin";

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const NotificationTitle = ({ record }: any) => {
  return <span>Edit {record ? `"${record.title}"` : ""}</span>;
};

interface RetFormValidator {
  title?: string;
  webhookUrl?: string;
  period?: string;
  activated?: string;
}

const NotificationEdit: React.FC<Props> = props => {
  const formValidator: ValidateForm = values => {
    const errors: RetFormValidator = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.webhookUrl) {
      errors.webhookUrl = 'Required' ;
    }
    if (!values.period) {
      errors.period = 'Required' ;
    }
    return errors;
  };
  return (
    <Edit title={<NotificationTitle />} {...props}>
      <SimpleForm validate={formValidator}>
        <TextInput
          source="title"
          validate={[minLength(3), maxLength(100), required()]}
          autoFocus />
        <TextInput
          source="webhookUrl"
          validate={[minLength(3), maxLength(100), required()]}/>
        <SelectInput
          source="period"
          choices ={[
            {id: 'day', name: 'Day'},
            {id: 'week', name: 'Week'},
            {id: 'month', name: 'Month'},
            {id: 'year', name: 'Year'}
          ]}
          defaultValue={'day'} />
        <BooleanInput source="activated" defaultValue="true"/>
      </SimpleForm>
    </Edit>
  );
}

export default NotificationEdit;
