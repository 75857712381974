import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import {  Edit,
          SimpleForm,
          BooleanInput,
          Toolbar,
          SaveButton,
          RaRecord,
          ValidateForm,
          NumberInput} from "react-admin";
import { useStoreContext } from "admin/store-context";
import { capitalizeFirstLetter } from "utilities/algorithm/string";

type RaProps = Omit<RaRecord, 'id'>;

const Title = observer(() => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Backend Settings`, [services.activeId]);
  return <span>Edit {label}</span>;
});

const EditToolbar: React.FC<RaProps> = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

interface RetFormValidator {
  ocr_enabled?: boolean;
  tokens_limit_enabled?: boolean
  tokens_limit_current?: number;
  tokens_limit_max?: number;  
}

const EditItem: React.FC<RaProps> = props => {

  const formValidator: ValidateForm = values => {
    const errors: RetFormValidator = {};
    return errors;
  };

  return (
  <Edit title={<Title />} {...props} >
      <SimpleForm 
          validate={formValidator}
          toolbar={<EditToolbar />} >
        <BooleanInput source="ocr_enabled" label = 'OCR enabled'/>
        <BooleanInput source="tokens_limit_enabled" label = 'Tokens Limits Enabled'/>
        <NumberInput source="tokens_limit_current" label = 'Tokens Limits Current'/>
        <NumberInput source="tokens_limit_max" label = 'Tokens Limits Max'/>
      </SimpleForm>
  </Edit>)
}

export default EditItem;