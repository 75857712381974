import React, { useEffect, useState } from 'react';
import {
  RaRecord,
    useRecordContext,
    useResourceContext} from 'react-admin';
import {
    Button,
    Autocomplete,
    Stack,
    TextField} from "@mui/material";
import {
    Permission} from 'admin/pages/types';
import { usePermission } from './hooks/permissions';

type Permissions = Array<Permission>;
type SelectedItem = Permission| null;

interface Props {
  record: RaRecord;
  exclude: Permissions;
  onAdd: (user: Permission | null) => void;
}

const PermissionsOfGroup: React.FC<Props> = props => {
  const {record} = props;
  const resource = useResourceContext();
  const [selected, setSelected] = useState<SelectedItem>(null);
  const {updatePermissions, permissions} = usePermission({excluded: props.exclude})
  useEffect(() => {
      updatePermissions(() => {setSelected(null)});
      },
      [updatePermissions, resource, record, record.id, props.exclude]);
  const isActive = !!permissions.length && !!selected;
  return (
    <Stack direction='row' spacing={2}>
      <Autocomplete
          disabled = {permissions.length === 0}
          value = {isActive ? {...selected, label: selected.name}: null}
          id="find-user-email"
          options={permissions.map(({name, ...other}) => ({label: name, ...other}))}
          sx={{ minWidth: 300 }}
          renderInput={(params) => <TextField {...params} label="Permission Name" />}
          onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                setSelected(value? {...value, name: value.label}: null);
              }
              else {
                setSelected(null);
              }
            }}
          isOptionEqualToValue={(left, right) => left?.id === right?.id}/>
      <Button 
              disabled = {!isActive}
              variant="outlined"
              onClick={() => props.onAdd(selected)}>
          Add Permission
      </Button>
    </Stack>
  )
};

export default PermissionsOfGroup;
