import {Button} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNotify } from 'react-admin';
import {getNotificationTest} from 'connections/demo-spec-request';
import { useStoreContext } from "admin/store-context";
interface Props {
  id: string;
}

const NotificationTest: React.FC<Props> = (props) => {
  const {services} = useStoreContext();
  const notify = useNotify();
  return <Button
    size={"medium"}
    onClick={
      async () => {
        try{
          await getNotificationTest(services.activeId?? '', props.id)
        }
        catch (e) {
          // @ts-ignore
          notify(e.message, { type: 'warning' });
        }
      }
  }>Test</Button>
}

export default observer(NotificationTest)
