import ServicesModel from "models/services/services";
import { createContext, useContext } from "react";
import mainStore from "./store-instance";

interface IStoreContext {
  services: ServicesModel
}

const StoreContext = createContext<IStoreContext>(mainStore as IStoreContext);
export const StoreContextProvider = StoreContext.Provider;
export const useStoreContext = () => useContext(StoreContext);
