import {RaRecord } from "react-admin";
import {
  Grid,
  Typography} from "@mui/material";
import { Box } from "@mui/system";

type RaProps = Omit<RaRecord, 'id'>;

const PropertiesPanel = (props: any) => {
  return (
    <>
      <TranslateFeedbacksPanel {...props}/>
    </>
  )
}

const TranslateFeedbacksPanel: React.FC<RaProps> = props => {
  const feedbackID = props.record.id;
  const record = props.record;

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            Original text
          </Typography>
          <Typography variant="body1">
            {record ? record.original_text : 'Original text.'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            Translated text
          </Typography>
          <Typography variant="body1">
            {record ? record.translation_text : 'Translated text.'}
          </Typography>
        </Grid>
      </Grid>
    </Box>)
}

export default PropertiesPanel
