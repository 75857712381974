import { Resource, ResourceElement } from 'react-admin';
import Downloads from './list';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Информации о запросах на загрузку тм-апи продукта
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const AppDownloadsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку)
  return permissions
              ? <Resource name={getResourcePath('downloads')}
                          list={Downloads}
                          icon={BrowserUpdatedIcon}
                          options={{label: "Downloads"}} />
              : null;
}


export default AppDownloadsPage;
