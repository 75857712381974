import { makeAutoObservable } from "mobx";
import { ServicesItems, Services, ServiceDesc, ServiceUrls } from "./types";
import adminProvider from 'providers/adminportal';

const getEnvValue = (name: string, defaultValue: string = '') => {
  return process.env[name] || defaultValue;
};

class UnknownService extends Error {
  constructor(public serviceName: string) {
    super(`Unknown service name`);
  }
}

class ServicesModel {
  private services_: ServicesItems = {}
  private serviceUrls: ServiceUrls | null = null;
  private activeId_: string | null = parseServiceFromUrl(window.location.hash);

  constructor() {
    makeAutoObservable(this);
  }

  get items(): ServicesItems | null {
    return this.services_;
  }

  get urls(): ServiceUrls | undefined | null {
    return this.serviceUrls;
  }

  get gatewayUrl(): string | null {
    return getEnvValue('REACT_APP_GATEWAY_URL', this.urls?.gatewayUrl);
  }

  get loginUrl(): string | null {
    return getEnvValue('REACT_APP_LOGIN_URL', this.urls?.loginUrl);
  }

  get backendUrl(): string | null {
    return getEnvValue('REACT_APP_BACKEND_URL', this.urls?.backend) ?? '';
  }

  get active(): ServiceDesc | null {
    return this.activeId_
              ? this.services_[this.activeId_] ?? null
              : null;
  }
  
  get activeId(): string | null {
    return this.activeId_;
  }

  set activeId(id: string | null) {
    if (id !== null) {
      const service = this.services_[id];
      if (!service) {
        throw new UnknownService(id);
      }
    }
    this.activeId_ = id;
  }

  async update(): Promise<ServicesModel>{
    const {urls, items} = await adminProvider.getServices<Services>();
    this.services = items;
    this.serviceUrls = urls;
    return this;
  }

  private set services(services: ServicesItems) {
    this.services_ = services
  }
}

function parseServiceFromUrl(url: string) {
  let serviceName = '';
  if (url.startsWith('#/')) {
    const name = url.substring(2).split('/')[0];
    if (name !== 'services') {
      serviceName = name
    }
  }
  return serviceName;
}

export default ServicesModel;