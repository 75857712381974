import React, { useEffect, useState, useCallback } from 'react';
import {Card, CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import DateRange, { DateType } from 'components/date-range';
import {DateRange as DateRangeInfo, toDateCount, shiftDay, roundDate, dateRangeToGtLte, ItemIds} from 'components/date-range/types'
import FlexTable from 'components/static-data-grid/flex-table';
import DateChart from 'components/date-chart-list';
import { OnLinkClick } from 'components/buttons/link';
import { Statistics } from './statistics-response';
import Percentage from 'components/perecentage';
import OsTable from './statistics/operation-systems';
import LanguagesTable from './statistics/languages';
import CityTable from './statistics/cities';
import BrowsersTable from './statistics/browsers';
import BrowsersWithVersionTable from './statistics/browser-version';
import CountryMap, { CountryTable } from './statistics/country-maps';
import { makeStyles } from "@mui/styles";
import { GetListParams, useDataProvider } from 'react-admin';
import {DateRange as DateCount} from 'providers/statistics/date-range';
import { getResourcePath } from 'admin/pages-factory';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2px',
    padding: '10px',
  },
 
  content: {
    margin: '2px',
    paddingRight: '25px'
  }
}));

function genStatisticsParams(dateType: DateType, range: DateCount): GetListParams {
  const retParams: GetListParams = {
    pagination: {
      page: 0,
      perPage: 0
    },
    sort: {
      field: '',
      order: ''
    },
    filter: {
      dateCount: range.dateCount,
    }
  }
  retParams.filter[dateType] = range.start;

  return retParams;
}


interface Props {
  // apiProvider: StatisticProvider<Statistics>;
  onDateChanged: (range: DateRangeInfo, type: DateType) => void;
  dateRange?: DateRangeInfo;
  dateType: DateType;
  onLink: OnLinkClick;
}

const StatisticsPanel: React.FC<Props> = ({/*apiProvider,*/ dateRange, onDateChanged, dateType, onLink}) => {
  const dataProvider = useDataProvider();
  const getWorkRange =  useCallback((range: DateRangeInfo | undefined) => {
      const currentDate = roundDate(new Date());
      return range ?? {start: shiftDay(currentDate, -7), end: currentDate};
    }, []);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [statisticData, setStatisticData] = useState<Statistics | null>(null);
  
  useEffect(() => {
      const doRequestStatic = (range: DateRangeInfo) => {
        const date = toDateCount(range);
        if (date) {
          setLoadingState(true);
          dataProvider.getList(getResourcePath('statistics/clients'), genStatisticsParams(dateType, date))
                .then(({ data }) => {
                    setStatisticData(data as unknown as Statistics);
                    setLoadingState(false);
                })
               .catch(error => {
                  // const {status = 401} = error;
                  // setLogout(status === 401 || status === 403);
                  setLoadingState(false);
                })
        }
        else {
          console.error(`Invalid date value ${range}`);
        }
      }
      if (!isLoading) {
        doRequestStatic(getWorkRange(dateRange));
      }

    }, [dateRange, dataProvider, getWorkRange, dateType ])

  useEffect(() => {
      return  () => {
        setLoadingState(false);
      }
    }, [])

  const dateRangeChanged = (value: DateRangeInfo, dateType?: DateType) => {
    onDateChanged(value, dateType?? DateType.creation);
  }

  const createFilter = () => {
    return dateRangeToGtLte(
              dateType === 'update'
                ? 'date'
                : dateType,
              getWorkRange(dateRange))
  }

  const classes = useStyles();
  let StaticContent: React.ReactNode| undefined = (<CircularProgress />)
  if (!isLoading && statisticData) {
    const statistics = statisticData[0];
    const {os, cities, languages, browsers, browserVersion, countries, items: curItems = []} = statistics.ranges[0];
    const {items: prevItems = []} = statistics.ranges[1];
    const extractCountAtRange = (items: ItemIds<string>) => {
        return items.reduce(
          (acc, item) => {
            const {ids, count} = item;
            return acc + (count !== undefined ? count : ids.length)
          }, 0);
      };
    StaticContent = (
      <Card>
        <Percentage 
            previous = {extractCountAtRange(prevItems)}
            current = {extractCountAtRange(curItems)}
            onLink = {onLink}
            linkGenerator={() => ({
                  redirectTo: 'list',
                  pathTo: getResourcePath('/clients'),
                  filter: createFilter()
                })
              }>
                {`${dateType === 'creation' ? 'New ' : ''}Clients`}
        </Percentage>
        {statistics.ranges && <DateChart data={statistics.ranges} />}
        <FlexTable>
          {browsers && browsers.length > 0 && <BrowsersTable resource={browsers} filter={createFilter()} onLink = {onLink}/>}
          {browserVersion && browserVersion.length > 0 && <BrowsersWithVersionTable resource={browserVersion} filter={createFilter()} onLink = {onLink}/>}
        </FlexTable>
        <FlexTable>
          {os && os.length > 0 && <OsTable resource={os} filter={createFilter()} onLink = {onLink}/>}
          {languages && languages.length > 0 &&<LanguagesTable resource={languages} filter={createFilter()} onLink = {onLink}/>}
        </FlexTable>
        <FlexTable>
          {cities && cities.length > 0 && <CityTable resource={cities} filter={createFilter()} onLink = {onLink}/>}
          {countries && countries.length > 0 && <CountryTable resource={countries} filter={createFilter()} onLink = {onLink}/>}
        </FlexTable>
        <CountryMap resource={statistics.ranges[0].countries}/>
      </Card>
    )
  }
  else if (!isLoading) {
    StaticContent = undefined;
  }


	return (<Card>
                <Box className={classes.root} >
                  <DateRange onChange={dateRangeChanged} dateRange={getWorkRange(dateRange)} dateType={dateType}/>
                </Box>
                {StaticContent}
              </Card>)
}

export default StatisticsPanel;