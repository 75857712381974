/**
 * Реализация отображение списка клиентво
 */

import React, { useMemo, useState } from 'react';
import get from 'lodash/get';

import {
    Datagrid,
    DateField,
    List,
    RaRecord,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput, 
    useRecordContext} from 'react-admin';
import ButtonLink from 'components/buttons/link';
import ListField from 'components/fields/list-field';
import Tablist from 'components/tabs/tab-list';
import StatisticsPanel from './statistics';
import {DateRange as DateRangeInfo} from 'components/date-range/types';
import { DateType } from 'components/date-range';
import DateRanges from 'components/date-range/ra-date-range';
import { useStoreContext } from 'admin/store-context';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { getResourcePath } from 'admin/pages-factory';

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
    const retList: React.ReactElement[] = 
    [
        <TextInput source="id" label='DCI' autoFocus/>,
        <DateRanges source='creation' label='Created'/>,
        <DateRanges source="date" label='Update'/>,
        <TextInput source="ipAddress" label = 'IP Address' autoFocus/>,
        <TextInput source="browser" autoFocus/>,
        <TextInput source="browserVersion" label='Browser Version' autoFocus/>,
        <TextInput source="os" label = "Operation system" autoFocus/>,
        <TextInput source="language" label = 'Locale/Language' autoFocus/>,
        <TextInput source="country" label='Country' autoFocus/>,
        <TextInput source="city" label='City' autoFocus/>
    ];
    return retList;
}

const DetailPanel: React.FC<RaProps> = (props: RaProps) =>{
  const record = useRecordContext();
  const {researches, downloads, questions} = record;
  const emptyCount = get(researches, 'counters.empty', undefined);
  return (
      <TabbedShowLayout>
        <Tab label='researches'>
          {researches && <TextField label='Count:' source='researches.counters.requests' />}
          {emptyCount && <TextField label='Empty:' source='researches.counters.empty' />}
          {researches && <DateField source="researches.dateRange.start"
                        label = 'First at:'/> }
          {researches && <DateField source="researches.dateRange.end"
                        label = 'Last at:'/> }
          {researches && 
            <ButtonLink label = 'Open Researches'
                            redirectTo='list'
                            pathTo={getResourcePath('/researches')}
                            filter={{dci: record.id}}/>}
        </Tab>
        <Tab label="downloads">
          {downloads && <TextField label="Requests:" source="downloads.counters.requests" />}
          {downloads && <TextField label="Downloads:" source="downloads.counters.confirmed" />}
          {downloads && <ListField key='names' label='Names:' source='downloads.names'/>}
          {downloads && <ListField key='emails' label='Emails:' source='downloads.emails'/>}
          {downloads && <DateField source="downloads.dateRange.start"
                        label = 'First at:'/> }
          {downloads && <DateField source="downloads.dateRange.end"
                        label = 'Last at:'/> }
          {downloads && 
            <ButtonLink label = 'Open Downloads'
                            redirectTo='list'
                            pathTo={getResourcePath('/downloads')}
                            filter={{dci: record.id}}/>}
        </Tab>
        <Tab label="questions">
          {questions && <TextField label="Count:" source="questions.count" />}
          {questions && <ListField key='names' label='Names:' source='questions.names'/>}
          {questions && <ListField key='emails' label='Emails:' source='questions.emails'/>}
          {questions && <ListField key='phones' label='Phones:' source='questions.phones'/>}
          {questions && <DateField source="questions.dateRange.start"
                        label = 'First at:'/> }
          {questions && <DateField source="questions.dateRange.end"
                        label = 'Last at:'/> }
          {questions && 
            <ButtonLink label = 'Open Questions'
                            redirectTo='list'
                            pathTo={getResourcePath('/feedbacks')}
                            filter={{dci: record.id}}/>}

        </Tab>
      </TabbedShowLayout>);
}

const ShownDetail = (props: RaProps) => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const WebClientsList: React.FC<Props> = ({ permissions, ...props }) => {
   return (
    <>
     <List
        {...props}
        title=' '
        filters={getFilters(permissions)}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={25}
        bulkActionButtons={false}>
          <Datagrid 
              bulkActionButtons={false}
              expand={<ShownDetail {...props}/>}>
                <TextField source="id" label='DCI'/>
                <DateField source="creation" label='Created'/>
                <DateField source="date" label='Update'/>
                <TextField source="ipAddress" label='IP Address'/>
                <TextField source="browser" label='Browser'/>
                <TextField source="browserVersion" label='Browser Version'/>
                <TextField source="os" label="Operation system"/>
                <TextField source="language" label='Locale/Language'/>
                <TextField source="country" label='Country'/>
                <TextField source="city" label='City'/>
          </Datagrid>
    </List>
    </>
    )
};

interface State {
  range?: DateRangeInfo;
  type: DateType
}

const MainPanel: React.FC<Props> = (props) => {
  const {services} = useStoreContext();
    const [date, setDate] = useState<State>({
        range: undefined,
        type: DateType.creation
    });
  const [active, setActiveTab] = React.useState<string>('Statistic');
  const listTab = [
      {
        label: 'Statistic',
        render: 
            <StatisticsPanel 
                    // apiProvider={statisticProvider}
                    dateRange={date.range}
                    dateType={date.type}
                    onDateChanged={(range, type) => setDate({range, type})}
                    onLink={() => setActiveTab('Data')}/>
      },
      {
        label: 'Data',
        render: <WebClientsList {...props}/>
      }
    ];

  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Web Clients`, [services.activeId]);
  return <Tablist list={listTab} active={active} onActive={setActiveTab} label={label}/>
}

export default observer(MainPanel);
