/**
 * Реализация отображение списка исследованных документов
 */
import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    RaRecord,
    Show,
    SimpleShowLayout,
    TextInput,
    Title,
    useRecordContext } from 'react-admin';
import TextViewField from 'components/text-view';
import SnapShotTextField from 'components/snapshot-text-field';
import ButtonLink from 'components/buttons/link';
import {DateRange as DateRangeInfo} from 'components/date-range/types';
import StatisticsPanel from './statistics';
import Tablist from 'components/tabs/tab-list';
import DateRanges from 'components/date-range/ra-date-range';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';
import { getResourcePath } from 'admin/pages-factory';

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
      <DateRanges source='date' label='Date'/>,
      <TextInput source="text" label = 'User Text' autoFocus/>,
  ];
  return retList;
}

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps{
  permission: string;
}

const DetailPanel: React.FC<RaProps> = props => {
  const record = useRecordContext();
  return  (
    <SimpleShowLayout>
        <TextViewField source="text" label = 'Text:'/>
        {record.researches && 
              <NumberField  source="researches.count" 
                            label = 'Researches Count:'/>}
        {record.researches && 
              <DateField source="researches.dateRange.start"
                          label = 'First at'/>}
        {record.researches && record.researches.count > 1 && 
              <DateField source="researches.dateRange.end"
                          label = 'Last at:'/>}
        {record.researches && 
              <ButtonLink label = 'Open Researches'
                          redirectTo='list'
                          pathTo={getResourcePath('/researches')}
                          filter={{documentID: record.id}}/>}
    </SimpleShowLayout>);
}

const ShowDetail: React.FC<RaProps> = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const TextList: React.FC<Props> = ({ permissions, ...props }) => {
  return (
    <>
      <List
            {...props}
            title=' '
            filters={getFilters(permissions)}
            sort={{ field: 'date', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}>
        <Datagrid 
            expand={<ShowDetail {...props}/>}
            bulkActionButtons={false}>
              <DateField source="date" label='Date'/>
              <SnapShotTextField source="text" label='Text'/>
        </Datagrid>
      </List>
    </>
  );
}

const MainPanel: React.FC<Props> = props => {
  const {services} = useStoreContext();
  const [dateRange, setDateRange] = useState<DateRangeInfo | undefined>(undefined);
  const [active, setActiveTab] = React.useState<string>('Statistic');
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Documents`, [services.activeId]);
  const listTab = [
    {
      label: 'Statistic',
      render: <StatisticsPanel dateRange={dateRange} onDateChanged={setDateRange}/>
    },
    {
      label: 'Data',
      render: <TextList {...props}/>
    }
  ];
  return (
    <Box>
      <Title title={label} />
      <Tablist list={listTab} active={active} onActive={setActiveTab}/>
    </Box>
  )
}

export default observer(MainPanel);