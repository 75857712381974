import {DateRange as DateCount} from 'providers/statistics/date-range';
import differenceInDays from 'date-fns/differenceInDays';

export interface DateRange {
  start: Date; // | null | undefined;
  end: Date; // | null | undefined;
}

export function toDateCount(dateRange: DateRange): DateCount | null{
  let dateResult: DateCount | null = null;
  // нулевой старт
  if (!dateRange.start) {
    if (dateRange.end) {
      dateResult = {
        start: dateRange.end,
        dateCount: 1
      };
    }
  }
  // нулевой конец
  if (!dateRange.end) {
    if (dateRange.start) {
      dateResult = {
        start: dateRange.start,
        dateCount: 1
      };
    }
  }
  if (dateRange.start && dateRange.end) {
    dateResult = {
      start: dateRange.start,
      dateCount: dateRange.start.getTime() !== dateRange.end.getTime() ? differenceInDays(dateRange.end, dateRange.start) : 1
    };
  }

  return dateResult;
}

export function shiftDay(date: Date, day: number): Date {
  const resultDay = new Date(date);
  resultDay.setDate(date.getDate() + day);
  return resultDay;
}

export function roundDate(date: Date){
  return new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0,0)
}

export interface GtLteDate {
  gt: Date,
  lte: Date
}

export function dateRangeToGtLte(propName: string, range: DateRange) {
  const result: Record<string, GtLteDate> = {};
  result[`${propName}`] = {
    gt: roundDate(range.start),
    lte: roundDate(range.end)
  };
  return result;
}

interface IdsPerDay<T> {
  date: Date;
  ids: Array<T>;
  count?: number;
}

export type ItemIds<T> = Array<IdsPerDay<T>>;

export interface ItemsPerDateRange<T> {
  dateRange: DateRange;
  items: ItemIds<T>;
}

export type RangesWithStatistics<T> = Array<ItemsPerDateRange<T>>

