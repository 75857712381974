import React, { CSSProperties } from 'react';

/**
 * Формирует подкрашенный html-текст по планарному и фильтру
 * @param text - исходный планарный текст
 * @param filter - текст-фильтр, по которому будет формироваться подкраска
 * @param style - стиль подкраски
 * @returns - массив react-нодов
 */
export function highlightFilteredText(
      text: string,
      filter: string,
      style: CSSProperties = {backgroundColor: 'rgb(210, 210 ,210)', borderRadius: '2px'}
  ): React.ReactNode[] {
  let Tags: React.ReactNode[] = [];
  // если фильтр существует
  if (filter) {
    // ищем и проходимся по всем элементам фильтра
    const reg = new RegExp(escapeRegExp(filter), 'ig') ;
    {
      let array: RegExpExecArray | null = null;
      let pos = 0;
      while ((array = reg.exec(text)) !== null) {
        // фрагмент текста который нам не следует подкрашивать
        Tags.push(text.substring(pos, array.index));
        // фрагмент текста который мы подкрашиваем
        Tags.push(<span style={style}>{array[0]}</span>)
        pos = reg.lastIndex;
      }
      // последний элемент в поиске (или единственный) - не подкрашенный текст
      Tags.push(text.substring(pos));
    }
  }
  else {
    // если фильтра нет - весь текст добавляем как есть
    Tags.push(text);
  }

  return Tags;
}

/**
 * модификация эскейп-символов в обычной строке интерпертируемой как регексп
 * @param string 
 * @returns 
 */
function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Извлечение фрагмента текста (по возможности заданной длины) по указанному фильтру
 * Берется фрагмент ближайший к первому найденному вхождению
 * @param text - исходный текст, на базе которого будет формироватся фрагмент
 * @param filter - фильтр, текст для поиска
 * @param rangeLen - желаемая длина фрагмента
 * @param factor - значение от [0 - 1], где 0 - найденный фильтр будет находится в начале фрагмента, 1 - в конце
 * @returns - полученный фрагмент текста
 */
export function extractFragment(
      text: string,
      filter: string,
      rangeLen: number,
      factor: number = 0.5)
  : string {
  const bestStart = filter ? rangeLen * factor : 0;
  if (filter && text && text.length > rangeLen) {
    const reg = new RegExp(escapeRegExp(filter), 'i') ;
    const result = reg.exec(text);
    const posFilter = result ? result.index : -1;
    if (posFilter > -1) {
      const start = Math.max(posFilter - bestStart, 0);
      const end = Math.min(start + rangeLen, text.length);
      return `${start > 0? '...' : ''}${text.slice(start, end)}${end < text.length ? '...': ''}`;
    }
  }

  return text
          ? text.length > rangeLen
            ? text.slice(0, rangeLen) + '...'
            : text
          : '';

}
