import React from 'react';

import {Card, Typography} from '@mui/material';
import { makeStyles, withStyles } from "@mui/styles";
import { OnLinkClick, ResourceLink } from 'components/buttons/link';
import { LinkGenerator } from 'components/static-data-grid/data-table';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '10px',
    padding: '10px'
  },
  increasingValue: {
    color: "#00FF00"
  },
  decreaseValue: {
    color: "#FF0000"
  },
  arrow: {
    fontFamily: 'monospace'
  }
}));


const HeaderTag = withStyles({
  root: {
    fontSize: '20px'
  }
})(Typography);

const ValueTag = withStyles({
  root: {
    fontSize: '40px'
  }
})(Typography);

interface SubProps {
  value: number | undefined;
}

const Arrow: React.FC<SubProps> = props => {
  const {value = 0} = props;
  const content = value > 0 
                    ? '↑' 
                    : value < 0 
                      ? '↓'
                    : undefined;
  const classes = useStyles();
  return content 
            ? (<Typography component='span' className={classes.arrow} style={{fontFamily: 'monospace'}}>
                {content}
              </Typography>)
            : null
}

const PercentageDynamic: React.FC<SubProps> = props => {
  const {value} = props;
  const percentage = value !== undefined ? ` ${Math.abs(value).toFixed(2)} %` : '—';
  const classes = useStyles();
  let className = undefined;
  if (value !== undefined) {
    className = value > 0 
      ? classes.increasingValue
      : value < 0 
        ? classes.decreaseValue
        : undefined
  }
  return <Typography component='span' 
                      className={className}>
            <Arrow value={value} />
            {percentage}
        </Typography>
}


interface Props {
  current: number;
  previous: number;
  linkGenerator?: LinkGenerator;
  onLink?: OnLinkClick;
}

const Percentage: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const classes = useStyles();
  const {current, previous, linkGenerator} = props;
  const percentage = previous ? (current / previous - 1) * 100 : undefined;
  return (
    <Card className={classes.content}>
      <HeaderTag variant="h6">
            {props.children}
      </HeaderTag>
      <ValueTag variant="h3">
      {linkGenerator 
            ? <ResourceLink label={current.toString()} onLink={props.onLink} {...linkGenerator('')}/>
            : current}
      </ValueTag>
      <PercentageDynamic value={percentage} />
    </Card>
  );
}

export default Percentage;