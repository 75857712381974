import {  minLength,
          maxLength,
          required,
          SimpleForm,
          TextInput,
          BooleanInput,
          AutocompleteArrayInput, 
          Create,
          RaRecord,
          ValidateForm} from "react-admin";
import { knownOperations } from "./operations";

type RaProps = Omit<RaRecord, 'id'>;

const Title = ({ record }: any) => {
  return <span>Create language supports</span>;
};

interface RetFormValidator {
  language?: string;
  limitedCovarage?: boolean;
  operations?: Array<string>;
  abbreviation?: string;
}

const CreateForm: React.FC<RaProps> = props => {

  const formValidator: ValidateForm = values => {
      const errors: RetFormValidator = {};
      if (!values.language) {
        errors.language = 'Required';
      }
      return errors;
    };

  return (
    <Create title={<Title />} {...props}>
      <SimpleForm validate={formValidator}>
        <TextInput source="language"
                  validate={[minLength(3), maxLength(50), required()]}/>
        <TextInput 
                 source="abbreviation"
                 label = 'Abbreviation'
                 validate={[minLength(3), maxLength(50), required()]}/>
        <BooleanInput source="limitedCovarage" label = 'Limited'/>
        <AutocompleteArrayInput 
                  source='operations'
                  label = 'Operations'
                  choices={knownOperations.map(operation => ({id: operation, name: operation}))}/>

      </SimpleForm>
    </Create>
  );
} 

export default CreateForm;