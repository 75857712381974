import { BooleanField, BooleanInput, FunctionField } from "react-admin";

interface IProps {
  label: string;
  source: string;
}
/**
 * Usually called using:
 *   <BooleanOppositeField label="Enabled" source="disabled"/>
 */
export const BooleanOppositeField = (props: IProps) => {
  return (
    <FunctionField {...props} render={(record: any | undefined, source: string | undefined) =>
      <BooleanField source="enabled" record={{ ...record, enabled: !(record![source!]) }} />}
    />
  );
};


/**
 * Usually called using:
 *   <BooleanOppositeInput label="Enabled" source="disabled" />
 */
export const BooleanOppositeInput = (props: IProps) => {
  return (
    <BooleanInput format={(v: boolean) => !v} parse={(v: boolean) => !v} {...props} />
  )
}