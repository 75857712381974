import React from 'react';
import { CountPerField as Info } from './response';
import DataTable from 'components/static-data-grid/data-table';

const heads = [
  {
    id: 'id',
    label: `Product version`
  },
  {
    id: 'count',
    label: `Download count`,
    isNumeric: true
  },
  {
    id: 'precentage',
    label: `Download, %`,
    isNumeric: true
  }
]

interface Props {
  resource: Array<Info>;
}

const VersionsTable: React.FC<Props> = props => {
  return <DataTable resource={props.resource} heads={heads} sortBy='count' />
}

export default VersionsTable;