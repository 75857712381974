import React, { useMemo } from 'react';

import {
    Datagrid,
    List,
    TextInput,
    BooleanInput,
    EditButton,
    BooleanField,
    AutocompleteArrayInput,
    RaRecord} from 'react-admin';
import { Box, Typography } from '@mui/material';
import SnapShotTextField from 'components/snapshot-text-field';
import ListField from 'components/fields/list-field';
import { knownOperations } from './operations';
import { useStoreContext } from 'admin/store-context';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { observer } from 'mobx-react-lite';

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
    <TextInput source="language" label = 'Language' autoFocus/>,
    <TextInput source="abbreviation" label = 'Abbreviation' autoFocus/>,
    <BooleanInput source="limitedCovarage" label = 'Limited'/>,
    <AutocompleteArrayInput 
                  source='operations'
                  label = 'Operations'
                  choices={knownOperations.map(operation => ({id: operation, name: operation}))}/>

  ];
  return retList;
}

const Empty = () => {
  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Empty Setting
          </Typography>
      </Box>
  );
};

const LanguageSupportsPanel: React.FC<Props> = ({ permissions, ...props }) => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Notifications`, [services.activeId]);
  return (
      <List
            {...props}
            empty={<Empty />}
            title={label}
            filters={getFilters(permissions)}
            sort={{ field: 'language', order: 'ASC' }}
            perPage={25}
            //actions={<ListActions/>}
            bulkActionButtons={false}>
        <Datagrid 
          bulkActionButtons={false}>
            <SnapShotTextField source="language" label = 'Language'/>
            <ListField source='operations' label='Operations'/>
            <SnapShotTextField source="abbreviation" label = 'Abbreviation'/>
            <BooleanField source="limitedCovarage" label = 'Limited' looseValue={true}/>
            <EditButton />
        </Datagrid>
      </List>
  );
}


export default observer(LanguageSupportsPanel);