/**
 * Реализация отображение списка загруженных pdf файлов в сервисе OCR
 */

import React, { useEffect, useMemo, useState } from 'react';

import {
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    RaRecord,
    TextField, 
    BooleanField,
    BooleanInput,
    TextInput,
    Show,
    SimpleShowLayout,
    useRecordContext} from 'react-admin';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';
import DateRanges from 'components/date-range/ra-date-range';
import SnapShotTextField from 'components/snapshot-text-field';
import TextViewField from 'components/text-view';
import { Box, Typography } from '@mui/material';

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const DetailPanel: React.FC<RaProps> = props => {
  const [isUpdate, setUpdate] = useState(false);
  const record = props.record as RaRecord;
  useEffect(() => {
    setTimeout(()=> setUpdate(true), 500)
  }, [props.record])

  return  isUpdate 
    ?(<SimpleShowLayout record={props.record}>
        <TextField source="uuid" label='UUID:' />
        <TextField source="text" label = 'Text:'/>
        <TextField source="response" label = 'Response:'/>
        <TextField source="user.name" label='User Name:'/>
        <TextField source="feedback.description" label = 'Feedback (Description):'/>
    </SimpleShowLayout>)
    : null;
}

const ShowDetail: React.FC<RaProps> = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const Empty = () => {
  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
              No Messages Available
          </Typography>
      </Box>
  );
};

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
    const retList: React.ReactElement[] = 
    [
      <NumberInput source="id" label='ID' autoFocus/>,
      <TextInput source="uuid" label='UUID' autoFocus/>,
      <TextInput source="text" label='Text' autoFocus/>,
      <TextInput source="response" label='Response' autoFocus/>,
      <BooleanInput source="cancelled" label = 'Cancelled' autoFocus/>,
      <NumberInput source="processing_time" label='Processing Time' autoFocus/>,      
      <DateRanges source="created_at" label='Created At'/>,
      <DateRanges source="modified_at" label='Modified At'/>,
      <TextInput source="user.name" label='User Name' autoFocus/>,
      <TextInput source="user.email" label='User Email' autoFocus/>,
      <BooleanInput source="feedback.liked" label = 'Feedback (Liked)' autoFocus/>,
      <TextInput source="feedback.description" label='Feedback (Description)' autoFocus/>,
    ];
    return retList;
}

const MessageList: React.FC<Props> = ({ permissions, ...props }) => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Messages List`, [services.activeId]);
  
   return (
    <>
     <List
        {...props}
        empty={<Empty />}        
        title={label}
        filters={getFilters(permissions)}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}>
          <Datagrid 
              bulkActionButtons={false}
              expand={<ShowDetail {...props} />}
              expandSingle={true} >
                <SnapShotTextField source="text" label='Text' maxPrevLen={100} minWidth={200}/>
                <SnapShotTextField source="response" label='Response' maxPrevLen={100} minWidth={200}/>
                <BooleanField source="cancelled" label = 'Cancelled'/>
                <DateField source="created_at" label='Created At'/>
                <DateField source="modified_at" label='Modified At'/>,
                <NumberField source="processing_time" label='Processing Time'/>,              
                <TextField source="user.email" label='User Email'/>
                <BooleanField source="feedback.liked" label = 'Feedback (Liked)'/>
          </Datagrid>
    </List>
    </>
    )
};

export default observer(MessageList);
