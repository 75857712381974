/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    TextField,
    Show,
    NumberField,
    RaRecord,
    TextInput} from 'react-admin';
import DateRanges from 'components/date-range/ra-date-range';    
import PropertiesPanel from './properties-panel';

const ShowDetail: React.FC = (props: any) => {
  return (
    <Show
      /* disable the app title change when shown */
      {...props}
      title=" ">
        <PropertiesPanel {...props}/>
    </Show>);
}

const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
    <TextInput source="user_name" label='User Name'/>,
    <TextInput source="access_token" label='Access Token'/>,
    <TextInput source="ip_address" label='IP Address'/>,
    // <DateRanges source="created_at" label = 'Create At'/>,
    // <DateRanges source="modified_at" label = 'Modified At'/>
  ];
  return retList;
}

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const AccessTokensList: React.FC<Props> =  ({ permissions, ...props }) => {
  return <List
                title="Access Tokens"
                perPage={25}
                sort={{ field: 'modified_at', order: 'DESC' }}
                filters={getFilters(permissions)}
                bulkActionButtons={false}
                {...props}
                >
            <Datagrid 
                  bulkActionButtons={false}
                  expand={<ShowDetail />}
                  expandSingle>
                <TextField source="user_name" label='User Name'/>
                <TextField source="access_token" label='Access Token'/>
                <TextField source="ip_address" label='IP Address'/>
                <DateField source="created_at" label='Create At'/>
                <DateField source="modified_at" label='Modified At'/>
                <DateField source="last_accessed_at" label='Last Accessed At' showTime />
                <NumberField source="deployment_id" label='Deployment ID'/>
                <TextField source="user_agent" label='User Agent'/>
                <TextField source="country" label='Country'/>
                <TextField source="city" label='City'/>
            </Datagrid>
          </List>
}

export default AccessTokensList;
