export interface UserInfo {
  id: number;
  username: string;
  email: string;
  verified_email: boolean;
  deleted: boolean
};

export interface Permission {
  id: number;
  name: string;
};

export interface Group {
  id: number;
  name: string;
};

export const emptyParamList = {
  pagination: {
    page: 0,
    perPage: 0
  },
  sort: {
    field: '',
    order: ''
  },
  filter: {}
};
