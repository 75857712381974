import { useRecordContext } from 'react-admin';
import get from 'lodash.get';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    background: 'rgb(230, 230 ,230)',
    padding: '2px',
    lineHeight: '1.5',
    borderRadius: '4px'
  },
});

interface Props {
  label?: string;
  source: string;
  separator?: string;
  addLabel?: boolean;
}

const ListField: React.FC<Props> = props => {
  const record = useRecordContext(props);
  const {source, separator = ','} = props;
  const classes = useStyles();  
  const values: Array<any> = get(record, source);
  const Tags = values.map((item, index) => {
      return (<div key={item}>
              <span className={classes.root}>
                {item}
              </span>
              {index !== values.length - 1 ? separator: undefined}
              </div>)
    })
  return (<div>{Tags}</div>);
}

ListField.defaultProps = {
  addLabel: true,
};


export default ListField;
