import React from 'react';
import { makeStyles } from "@mui/styles";
import {Card} from '@mui/material';
import { LocationsField as Info } from './response';
import DataTable from './location-datagrid';

const heads = [
  {
    id: 'city',
    label: `City`
  },
  {
    id: 'countryFullName',
    label: `Country`
  },
  {
    id: 'count',
    label: `Researches`,
    isNumeric: true
  },
  {
    id: 'precentage',
    label: ` Researches,%`,
    isNumeric: true
  }
];

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '15px',
    border: "none",
    boxShadow: "none",
  }
}));


interface Props {
  resource: Array<Info>;
}

const LocationsTable: React.FC<Props> = props => {
  const classes = useStyles();
  return <Card className={classes.content}>
            <DataTable resource={props.resource} heads={heads} sortBy='count' />
          </Card>
}

export default LocationsTable;