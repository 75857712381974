//import PeopleIcon from '@material-ui/icons/People';
import { CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import ServicesList from './list';

/**
 * Компонент страницы - Пользователи
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const ServicesPage = (permissions: any) => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - пользователи
  return permissions
      ? (<CustomRoutes>
            <Route path="/services" element={<ServicesList />} />
            <Route path="/" element={<ServicesList />} />
        </CustomRoutes>)
      : null;
}


export default ServicesPage;
