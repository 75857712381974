/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import {RaRecord, useRecordContext } from 'react-admin';
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material"; 
import useGroupsForUser from './hooks/groups';
import EnterToGroup from './add-group';
import { useEffect } from 'react';
  

const UserGroups = (props: any) => {

const record = props.record as RaRecord;
const {
    groups,
    total,
    perPage,
    setPage,
    setPerPage,
    exitFromGroup,
    enterForGroup} = useGroupsForUser({userId: record.id});

const columns = [
  {
    field: "name",
    headerName: "Group Name",
    flex: 1,
    minWidth: 250,
    sortable: true
  },
  {
    field: "permission-record-action",
    headerName: "Action",
    minWidth: 250,
    width: 100,
    sortable: false,
    renderCell: (params: any) => {
      return (
              <Button 
                  onClick={() => {
                      exitFromGroup(params.id);
                    }}>
                      Delete
              </Button>
    )}
  }
];

return (
        <>
          <DataGrid
              rows={groups}
              columns={columns}
              rowCount={total}
              pageSize={perPage}
              onPageChange = {newPage => setPage(newPage)}
              onPageSizeChange={newPageSize => setPerPage(newPageSize)}
              autoHeight/>
          <EnterToGroup exclude={groups}
                    record={record}
                    onAdd={selected => {
                      if (selected) {
                        enterForGroup(selected)
                      }
                    }}/>
        </>);
}

export default UserGroups;
