import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { makeStyles } from "@mui/styles";

export interface Option<T> {
  key: string;
  title: string;
  getRange: () => T;
}

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0',
    paddingBottom: '0',
    display: 'inline-flex',
  },
  navPanel: {
    paddingTop: '0',
    paddingBottom: '0'
  }
}));


interface PresetProps {
  caption: string;
  typeRange: string;
  options: Array<Option<any>>;
  disabledFirst?: boolean;
  onSelect: (dateRange: any, itemName: string) => void;
}

const RangePreset: React.FC<PresetProps> = ({options: Ranges, onSelect, typeRange, caption, disabledFirst}) => {

  const getIndex = (key: string) => Ranges.findIndex(item => item.key === key);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(getIndex(typeRange));
  useEffect(() => {
          setSelectedIndex(Ranges.findIndex(item => item.key === typeRange))
      }, [typeRange, Ranges]);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    const range = Ranges[index].getRange();
    onSelect(range, Ranges[index].key);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <div className={classes.content}>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0px' }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          sx={{paddingTop: '0', paddingBottom: '0px' }}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={caption}
            secondary={Ranges[selectedIndex].title}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {Ranges.map((option, index) => (
          <MenuItem
            key={option.key}
            disabled={disabledFirst ? index === 0 : false}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default RangePreset;
