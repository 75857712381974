import React, { useEffect, useState } from 'react';
import {
    Button,
    Autocomplete,
    Stack,
    TextField} from "@mui/material";
import {
    UserInfo} from 'admin/pages/types';
import { useUsers } from './hooks/users';
import { RaRecord, useRecordContext, useResourceContext } from 'react-admin';

interface Props {
  record: RaRecord;
  excludeUsers: Array<UserInfo>;
  onAdd: (user: UserInfo | null) => void;
}

const AddUser: React.FC<Props> = props => {
  const {record} = props;
  const resource = useResourceContext();
  const [selected, setSelected] = useState<UserInfo| null>(null);
  const {users, updateUsers} = useUsers({excluded: props.excludeUsers})
  useEffect(() => {
      updateUsers(() => {setSelected(null)})
    }, [updateUsers, resource, record, record.id]);
  const isActive = !!users.length && !!selected;
  return (
    <Stack direction='row' spacing={2}>
      <Autocomplete
          disabled = {users.length === 0}
          value = {isActive ? {...selected, label: selected.email}: null}
          id="find-user-email"
          options={users.map(({email, ...other}) => ({label: email, ...other}))}
          sx={{ minWidth: 300 }}
          renderInput={(params) => <TextField {...params} label="Email" />}
          onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                setSelected(value? {...value, email: value.label}: null);
              }
              else {
                setSelected(null);
              }
            }}
          isOptionEqualToValue={(left, right) => left?.id === right?.id}/>
      <Autocomplete
          disabled = {users.length === 0}
          value = {isActive ? {...selected, label: selected.username}: null}
          id="find-user-name"
          options={users.map(({username, ...other}) => ({label: username, ...other}))}
          sx={{ minWidth: 300 }}
          renderInput={(params) => <TextField {...params} label="Username" />}
          onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                setSelected(value? {...value, username: value.label}: null);
              }
              else {
                setSelected(null);
              }
            }}
          isOptionEqualToValue={(left, right) => left?.id === right?.id}/>
      <Button 
              disabled = {!isActive}
              variant="outlined"
              onClick={() => props.onAdd(selected)}>
          Add User
      </Button>
    </Stack>
  )
};

export default AddUser;
