import { ResourceElement } from "react-admin";
import Page from "admin/pages/gpt/messages";

function buildPages(permissions: any): Array<ResourceElement> {
  const list = [
      Page(permissions),
   ].filter(item => item != null);
   return list as Array<ResourceElement>;
}

export default buildPages;
