import axios from "axios";
import JwtManager from "./jwt-manager";
import urls from "./urls";

// синглтон хранилища к JWT-токенe
export const jwtManager = new JwtManager();

function createAuthHttp() {
  return axios.create({validateStatus: status => (status >= 200 && status <=300)});
}
const authHttp = createAuthHttp();

/**
 * Запрос аутентификации 
 * @returns 
 */
async function authenticate() {
  return await checkToken(jwtManager.token);
}

async function checkToken (token: string) {
  const url = urls.gateway('/user');
  const result = await authHttp.get(
                    url,
                    {
                      headers: {
                        Authorization: `Bearer ${jwtManager.token}`
                      }
                    });
  return result.data;
}

async function logout() {
  const url = urls.gateway('/user/logout');
  const result = await authHttp.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwtManager.token}`
      }
    });
  return result.data;
}

async function login(username: string, password: string) {
  const url = urls.gateway('/user/login');
  const result = await authHttp.post(
    url,
    {
      email: username,
      password
    });
  return result.data.token;
}

export default {
  authenticate,
  checkToken,
  login,
  logout
}
