import { ResourceElement } from "react-admin";
import WebClientsPage from "admin/pages/demo/clients";
import ResearchesPage from "admin/pages/demo/researches";
import AnalyzedTextsPage from "admin/pages/demo/users-documents";
import DownloadsPage from "admin/pages/demo/downloads";
import FeedbacksPage from "admin/pages/demo/feedbacks";
import NotificationsPage from "admin/pages/demo/notifications";
import LanguagesPage from "admin/pages/demo/languages";
import BackendSettingsPage from "admin/pages/demo/settings";

function buildDemoPages(permissions: any): Array<ResourceElement> {
  const list = [
      WebClientsPage(permissions),
      AnalyzedTextsPage(permissions),
      ResearchesPage(permissions),
      DownloadsPage(permissions),
      FeedbacksPage(permissions),
      NotificationsPage(permissions),
      LanguagesPage(permissions),
      BackendSettingsPage(permissions)
   ].filter(item => item != null);
   return list as Array<ResourceElement>;
}

export default buildDemoPages;
