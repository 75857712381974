import axios, {AxiosInstance} from 'axios';
import { DataProvider } from 'ra-core';
import { stringify } from 'query-string';
import { GetListParams, GetManyReferenceParams } from 'react-admin';

interface SubPaths<T> {
  rootID?: T;
  subName?: string;
};

type NumIdSubPaths = SubPaths<number>;

export enum Method {
  put = 'PUT',
  patch = 'PATCH',
  post = 'POST'
}

interface MetaMethod {
  method: Method
}

interface MetaAdapt {
  discardID: boolean
}

type MetaInfo = Partial<NumIdSubPaths & MetaMethod & MetaAdapt>

function genSubPath(value: MetaInfo): string {
  let result = '';
  if (value) {
    const {rootID, subName} = value;
    result += rootID !== undefined ? `/${rootID}`: '';
    result += subName ? `/${subName}`: '';
  }
  return result;
}

function getMethod(value: MetaInfo) {
  return  value?.method ?? Method.put;
}

interface IPagQuery {
  sort: [string, string];
  range: [number, number];
  filter: any;
}

class PagBuilder {

  static stringify(query: IPagQuery) {
    const {sort, range, filter} = query
    const [sort_field, sort_order] = sort
    return stringify({
      sort_field,
      sort_order,
      offset: range[0],
      limit: range[1] - range[0] + 1,
      filter: JSON.stringify(filter),
   })
  }

  static buildParams(params: GetListParams): IPagQuery{
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
  
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    return {
      sort: [field, order],
      range: [rangeStart, rangeEnd],
      filter: flattenObject(params.filter)
    }
  }

  static buildHeader(resource: string, query: IPagQuery) {
    return {
              // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
              headers: {
                  Range: `${resource}=${query.range[0]}-${query.range[1]}`,
              }
          };
  }
}

interface DataPrefixPathImpl {
  getList: string
  getOne: string;
}

export type DataPrefixPath = Partial<DataPrefixPathImpl>;

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 * 
 * export default App;
 */
const provider = (
    apiUrl: string,
    httpClient: AxiosInstance = axios.create(),
    dataPrefix: DataPrefixPath = {},
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
      const queryParam = PagBuilder.buildParams(params);
      const meta: MetaInfo = params.meta;
      const mainResource = resource.split('/').pop();
      const options =
          countHeader === 'Content-Range'
              ? PagBuilder.buildHeader(meta?.subName ?? resource, queryParam)
              : {};
        const subPath = genSubPath(params.meta);
        const url = `${apiUrl}/${mainResource}${subPath}?${PagBuilder.stringify(queryParam)}`;
        return httpClient.get(url, options).then(({ headers, data }) => {
          const contentRange = headers[countHeader.toLowerCase()];
          if (!contentRange) {
              throw new Error(
                  `The ${countHeader} header is missing in the HTTP Response at ${url} by getList. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
              );
          }
          const rangeValue = contentRange !== undefined ? contentRange.split('/').pop(): ''
          return {
              data: dataPrefix?.getList 
                ? data[`${dataPrefix?.getList }`]
                : data,
              total:
                  countHeader === 'Content-Range'
                      ? rangeValue !== undefined? parseInt(rangeValue, 10): 0
                      : contentRange !== undefined? parseInt(contentRange) : 0,
          };
        });
    },

    getOne: (resource, params) => {
      const {id: paramsId, meta} = params;
      const id = paramsId === null || paramsId === undefined? '':  `/${paramsId}`;
      const subPath = genSubPath(meta);
      const mainResource = resource.split('/').pop();
      const url = `${apiUrl}/${mainResource}${subPath}${id}`;
      return httpClient.get(url).then(({ data }) => {
                return dataPrefix?.getOne 
                  ? data[`${dataPrefix?.getOne}`]
                  : data
              })
          },

    getMany: (resource, params) => {
        const subPath = genSubPath(params.meta);
        const mainResource = resource.split('/').pop();
        const url = `${apiUrl}/${mainResource}${subPath}`;
        return httpClient.get(url).then(({ data }) => ({ data }));
    },

    getManyReference: (resource, params) => {
        const queryParam = PagBuilder.buildParams(params);
        queryParam.filter = {
            ...params.filter,
            [params.target]: params.id,
          };

        const mainResource = resource.split('/').pop();
        const meta: MetaInfo = params.meta;
        const subPath = genSubPath(meta);
        const url = `${apiUrl}/${mainResource}${subPath}?${PagBuilder.stringify(queryParam)}`;
        const options =
            countHeader === 'Content-Range' && meta.subName
                ? PagBuilder.buildHeader(meta.subName, queryParam)
                : {};

        return httpClient.get(url, options).then(({ headers, data }) => {
            const contentRange = headers[countHeader.toLowerCase()];
            if (!headers[countHeader.toLowerCase()]) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response at ${url} by getMany. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            const rangeValue = contentRange !== undefined ? contentRange.split('/').pop(): ''
            return {
                data,
                total:
                    countHeader === 'Content-Range'
                        ? rangeValue !== undefined? parseInt(rangeValue, 10): 0
                        : contentRange !== undefined? parseInt(contentRange) : 0,
            };
        });
    },

    update: (resource, params) => {
      const metaInfo: MetaInfo = params.meta
      const subPath = genSubPath(metaInfo);
      const mainResource = resource.split('/').pop();
      const url = `${apiUrl}/${mainResource}${subPath}${!metaInfo.discardID ? `/${params.id}` : ``}`;
      const metaMethod = getMethod(params.meta);
      if (metaMethod === Method.patch) {
        return httpClient.patch(url, params.data)
          .then(({data}) => ({data}))
      }
      else {
        return httpClient.put(url, params.data)
          .then(({ data }) => ({ data }))
      }
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) => {
      const subPath = genSubPath(params.meta);
      const metaMethod = getMethod(params.meta);
      return Promise.all( params.ids.map(id => {
                            const url = `${apiUrl}${subPath}/${id}`;
                            return metaMethod === Method.patch
                                ? httpClient.patch(url, params.data)
                                : httpClient.put(url, params.data);
                          }))
                    .then(responses => ({ data: responses.map(({ data }) => data.id) }))
    },

    create: (resource, params) => {
      const mainResource = resource.split('/').pop();
      const subPath = genSubPath(params.meta);
      const url = `${apiUrl}/${mainResource}${subPath}`
      return httpClient.post(url, params.data)
                .then(({ data }) => ({
                    data: {
                      ...params.data,
                      id: data.id 
                    }
                }));
    },

    delete: (resource, params) => {
      const subPath = genSubPath(params.meta);
      const mainResource = resource.split('/').pop();
      const url = `${apiUrl}/${mainResource}${subPath}/${params.id}`
      return httpClient.delete(
                          url,
                          {
                            headers: {
                              'Content-Type': 'text/plain',
                            }
                          })
                      .then(({ data }) => ({ data }))
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) => {
      const subPath = genSubPath(params.meta);
      const mainResource = resource.split('/').pop();
      return Promise.all(
                        params.ids.map(id => {
                            const url = `${apiUrl}/${mainResource}${subPath}/${id}`;
                            return httpClient.delete(
                                      url,
                                      {
                                        headers: {
                                          'Content-Type': 'text/plain',
                                        }
                                      });
                            })
                    ).then(responses => ({
                        data: responses.map(({ data }) => data.id),
                    }))
    },
});

function flattenObject(ob: Record<string, any>, separator: string = '.'): Record<string, any> {
  var toReturn: Record<string, any>= {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + separator + x] = flatObject[x];
      }
    }
    else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export default provider;
