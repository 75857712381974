import React, { useEffect, useState } from 'react';
import {
  RaRecord,
    useRecordContext,
    useResourceContext} from 'react-admin';
import {
    Button,
    Autocomplete,
    Stack,
    TextField} from "@mui/material";
import { Group } from 'admin/pages/types';
import { useUserGroups } from './hooks/groups';

type Groups = Array<Group>;
type SelectedItem = Group| null;

interface Props {
  exclude: Groups;
  record: RaRecord;
  onAdd: (user: SelectedItem) => void;
}

const EnterToGroup: React.FC<Props> = props => {
  const {record} = props;
  const resource = useResourceContext();
  const [selected, setSelected] = useState<SelectedItem>(null);
  const {groups, updateGroups} = useUserGroups({excluded: props.exclude})
  useEffect(() => {
      updateGroups(() => setSelected(null))
    }, [updateGroups, resource, record, record.id]);
  const isActive = !!groups.length && !!selected;
  return (
    <Stack direction='row' spacing={2}>
      <Autocomplete
          disabled = {groups.length === 0}
          value = {isActive ? {...selected, label: selected.name}: null}
          id="find-group-name"
          options={groups.map(({name, ...other}) => ({label: name, ...other}))}
          sx={{ minWidth: 300 }}
          renderInput={(params) => <TextField {...params} label="Group Name" />}
          onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                setSelected(value? {...value, name: value.label}: null);
              }
              else {
                setSelected(null);
              }
            }}
          isOptionEqualToValue={(left, right) => left?.id === right?.id}/>
      <Button 
              disabled = {!isActive}
              variant="outlined"
              onClick={() => props.onAdd(selected)}>
          Attach at Group
      </Button>
    </Stack>
  )
};

export default EnterToGroup;
