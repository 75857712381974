import {
  required,
  SimpleForm,
  TextInput,
  minLength,
  maxLength,
  Create, SelectInput, BooleanInput, ValidateForm, RaRecord
} from "react-admin";

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const NotificationTitle = () => {
  return <span>Create new notification</span>;
};

interface RetFormValidator {
  title?: string;
  webhookUrl?: string;
  period?: string;
  activated?: boolean
}

const NotificationCreate: React.FC<Props> = props => {
  const formValidator: ValidateForm = values => {
    const errors: RetFormValidator = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.webhookUrl) {
      errors.webhookUrl = 'Required';
    }
    if (!values.period) {
      errors.period = 'Required';
    }
    return errors;
  };

  return (
    <Create title={<NotificationTitle />} {...props}>
      <SimpleForm validate={formValidator}>
        <TextInput
          source="title"
          validate={[required(), minLength(3), maxLength(100)]}
          autoFocus />
        <TextInput
          source="webhookUrl"
          validate={[required(), minLength(3), maxLength(100)]} />
        <SelectInput
          source="period"
          choices ={[
            {id: 'day', name: 'Day'},
            {id: 'week', name: 'Week'},
            {id: 'month', name: 'Month'},
            {id: 'year', name: 'Year'}
          ]}
          defaultValue={'day'} />
        <BooleanInput source="activated" defaultValue="false"/>
      </SimpleForm>
    </Create>
  );
}

export default NotificationCreate;
