import React, { useEffect, useState, useCallback } from 'react';
import {Box, Card, CircularProgress} from '@mui/material';
import DateRange, { DateType } from 'components/date-range';
import {DateRange as DateRangeInfo, toDateCount, shiftDay, roundDate, ItemIds} from 'components/date-range/types';
import { Statistics } from './response';
import Percentage from 'components/perecentage';
import { makeStyles } from "@mui/styles";
import DateChart from 'components/date-chart-list';
import ResearchTable from './researches-table';
import LocationsTable from './locations-table';
import { GetListParams, useDataProvider } from 'react-admin';
import {DateRange as DateCount} from 'providers/statistics/date-range';
import { getResourcePath } from 'admin/pages-factory';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '2px',
    padding: '10px'
  },
  oneLine: {
    display: 'flex'
  }
}));

function genStatisticsParams(dateType: DateType, range: DateCount): GetListParams {
  const retParams: GetListParams = {
    pagination: {
      page: 0,
      perPage: 0
    },
    sort: {
      field: '',
      order: ''
    },
    filter: {
      dateCount: range.dateCount,
    }
  }
  retParams.filter[dateType] = range.start;

  return retParams;
}

interface Props {
  onDateChanged: (range: DateRangeInfo) => void;
  dateRange?: DateRangeInfo;
}

const StatisticsPanel: React.FC<Props> = ({dateRange, onDateChanged}) => {
  const dataProvider = useDataProvider();
  const getWorkRange =  useCallback((range: DateRangeInfo | undefined) => {
      const currentDate = roundDate(new Date());
      return range ?? {start: shiftDay(currentDate, -7), end: currentDate};
    }, []);

  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [statisticData, setStatisticData] = useState<Statistics | null>(null);

  useEffect(() => {
      const doRequestStatic = (range: DateRangeInfo) => {
        const date = toDateCount(range);
        if (date) {
          setLoadingState(true);
          dataProvider.getList(getResourcePath('statistics/researches'), genStatisticsParams(DateType.creation, date))
                .then(({ data }) => {
                    setStatisticData(data as unknown as Statistics);
                })
               .catch(error => {
                  // const {status = 401} = error;
                  // setLogout(status === 401 || status === 403);
                })
                .finally(() => {
                  setLoadingState(false);
                });
        }
        else {
          console.error(`Invalid date value ${range}`);
        }
      }
      if (!isLoading) {
        doRequestStatic(getWorkRange(dateRange));
      }

    }, [dateRange, dataProvider, getWorkRange])

  const dateRangeChanged = (value: DateRangeInfo) => {
    onDateChanged(value)
  }

  const classes = useStyles();
  let StaticContent: React.ReactNode| undefined = (<CircularProgress />)
  if (!isLoading && statisticData) {
    const statistic = statisticData[0];
    const {items: curItems = [], dci, locations} = statistic.ranges[0];
    const {items: prevItems = []} = statistic.ranges[1];
    const extractCountAtRange = (items: ItemIds<string>) => {
        return items.reduce(
              (acc, item) => {
                const {ids, count} = item;
                return acc + (count !== undefined ? count : ids.length)
              }, 0);
      };
    StaticContent = (
      <Card>
        <Percentage 
            previous = {extractCountAtRange(prevItems)}
            current = {extractCountAtRange(curItems)} >
                Researches Count
        </Percentage>
        {dci && dci.length > 0 && <ResearchTable resource={dci} />}
        {locations && locations.length > 0 && <LocationsTable resource={locations} />}
        {statistic.ranges && <DateChart data={statistic.ranges} />}
      </Card>
    )
  }
  else if (!isLoading) {
    StaticContent = undefined;
  }
	return (
    <Card>
      <Box className={classes.content} >
        <DateRange onChange={dateRangeChanged} dateRange={getWorkRange(dateRange)}/>
      </Box>
      {StaticContent}
    </Card>
	);
}

export default StatisticsPanel;