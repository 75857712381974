import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  oneLine: {
    display: 'flex'
  }
}));


const FlexTable: React.FC<React.PropsWithChildren<{}>> = props => {
  const classes = useStyles();
  return (
    <Box component='span' className={classes.oneLine} sx={{flexDirection: {lg: 'row', sm: 'column'}, marginRight: {lg: '0px', sm: '20px'}}}>
      {props.children}
    </Box>
  )
}

export default FlexTable;
