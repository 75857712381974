import React from "react";
import { DateRange as NotStrictDateRange,
       shiftDay,
       roundDate,
       toDateCount, 
       ItemsPerDateRange,
       RangesWithStatistics} from 'components/date-range/types';
import { Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Tick,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type DateRange = Required<NotStrictDateRange>;


function generateSteps(dateRange: DateRange): Array<Date> {
  let result: Array<Date> = [];
  const start = dateRange.start;
  const counter = toDateCount(dateRange);
  if (counter) {
    const days = Math.abs(counter.dateCount);
    for (let day = 0; day <= days; day ++) {
      result.push(shiftDay(start, day));
    }
  }

  return result;
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '10px',
    position: 'relative',
    margin: '10px',
  }
}));

export const defaultPresets = [
  {
    backgroundColor: "rgba(66, 133 , 244, 0.2)",
    borderColor: "rgba(66, 133 , 244, 0.8)",
    borderWidth: 3,
    pointRadius: 0.6
  },
  {
    backgroundColor: "rgba(100, 100,100,0.2)",
    borderColor: "rgba(100, 100, 100, 0.8)",
    borderWidth: 1,
    borderDash: [5,5],
    pointRadius: 0.6
  }
]

interface ColorInfo {
  backgroundColor?: string;
  borderColor?: string;
  borderDash?: Array<number>;
  borderWidth: number;
  pointRadius?: number;
}

interface Presets extends ColorInfo{
  title?: string;
}

interface Props {
  data: RangesWithStatistics<string>;
  presets?: Array<Presets>
}

const DateChart: React.FC<Props> = (props) => {
  const fillingMissingDate = (range: ItemsPerDateRange<string>) => {
      // округляем значение дат до дней
      const items = range.items.map( ({date, ids, count}) => (
          {
            date: roundDate(new Date(date)),
            ids,
            count
          }
        ))
      // генерируем последовательность дней в ренже
      const dateRange = { start: roundDate(new Date(range.dateRange.start)), end: roundDate(new Date(range.dateRange.end))}
      const dateSteps: Array<Date> = generateSteps(dateRange);
      return {
        dateRange: dateRange,
        items: dateSteps.map(dateStep => {
            const content = items.find(item => item.date.getTime() === dateStep.getTime());
            return {
              date: dateStep,
              ids: content ? content.ids : [],
              count: content? content.count: undefined
            }
          })
      };
    }
  const data = props.data.map(range => fillingMissingDate(range));
  const mainRange = data[0];
  const dayDistance = toDateCount(mainRange.dateRange);
  const shortDate = (date: Date, year: "numeric" | "2-digit" = 'numeric') => date.toLocaleString("ru", {day: 'numeric', month: 'numeric', year: year});
  const rangeTitles = data.map((range, index) => {
      const title = props.presets && props.presets[index] && props.presets[index].title 
                  ?  props.presets[index].title 
                  : `${shortDate(range.dateRange.start)} - ${shortDate(range.dateRange.end)}`
      return title;
    });
  const getDateByLabel = (label: string, xStep: number) => {
      const index = rangeTitles.findIndex(title => title === label);
      return (index >= 0 && dayDistance)
              ? shiftDay(dayDistance.start, - dayDistance.dateCount * index + xStep)
              : null;
    }
  const dataView = {
      labels: mainRange.items.map(item => item.date),
      datasets: data.map((range, index) => {
        const preset = props.presets ? props.presets[index] : {title: undefined, ...defaultPresets[index]}
        const {title: userTitle, ...otherPresets} = preset;
        const other = {...defaultPresets[index], ...otherPresets};
        return {
          label: rangeTitles[index],
          data: range.items.map(({ids, count}) => count !== undefined ? count:  ids.length),
          ...other
        }
      })
    }
  let maxValue = dataView.datasets.reduce((acc, range) => {
      const max = range.data.reduce((inAcc, value) => {
          return Math.max(inAcc, value)
        }, 0);
        return Math.max(max, acc);
    }, 0);
  const classes = useStyles();
  const axisStep = mainRange.items.length > 50
                    ? Math.max(Math.ceil(mainRange.items.length / 10), 5)
                    : Math.max(Math.ceil(mainRange.items.length / 5 ), 3)
  const xAxisRender = (value: number | string, index: number, values: Tick[]) => {
      if (index === 0 || index === mainRange.items.length - 1) {
        return dataView.labels[index].toDateString()
      }
      else if (index % axisStep === 0) {
        const date = dataView.labels[index];
        return shortDate(date, '2-digit');
      }
      else {
        return null;
      }
    }
  return (
    <Card variant='outlined' square={true} className={classes.content}>
      <Line data={dataView}
            style={ {maxHeight: '400px', maxWidth: '100%'}}
            options={
                {
                  scales: {
                    x:{
                      ticks: {
                        callback: xAxisRender
                      }
                    },
                    y: {
                      min: 0,
                      max: maxValue + Math.ceil(maxValue * 1 / 10)?? 1
                    }
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context: any): string| string[] => {
                          let {label = ''} = context.dataset;
                          const {x: dateValue, y: count} = context.parsed;
                          if (label && label.length && dateValue !== null) {
                            const date = getDateByLabel(label, dateValue);
                            if (date && count !== null) {
                              label = `${shortDate(date).toString()} : ${count}`;
                            }
                          }
                          return label;
                        },
                        title: (contexts: any): string| string[] => ''
                      }
                    },
                  },
                  elements: { point: { hitRadius: 4, hoverRadius: 4 } }
                }}
                />
    </Card>
  );
}

export default DateChart;