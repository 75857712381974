/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React from 'react';
import {
    List,
    TextField } from 'react-admin';
import Datagrid from 'components/ra-data-grid';

const PermissionsList: React.FC = () => {
  return <List
              title="List Of User Permissions"
              bulkActionButtons={false}>
            <Datagrid bulkActionButtons={false}
                      expandSingle>
                <TextField source="name" label='Permission Name'/>
            </Datagrid>
        </List>
}

export default PermissionsList;
