import Cookies from 'js-cookie';

const getDomain = () => {
  return "." + window.location.hostname.split(".").slice(-2).join(".");
};

export const getCookieToken = () => {
  const localhost = process.env.REACT_APP_LOCALHOST || 'localhost';
  let token = (window.location.hostname === localhost)
      ? Cookies.get('tms_token')
      : Cookies.withAttributes({domain: getDomain()}).get('tms_token');
  if (token) {
    const parts = token.split(' ');
    token = parts.length > 0 ? parts[1] : undefined;
  }
  return token;
};

export const clearCookies = () => {
  Cookies.remove('tms_token');
  Cookies.remove('tms_email');
  Cookies.remove('tms_token', {domain: getDomain()});
  Cookies.remove('tms_email', {domain: getDomain()});
};
