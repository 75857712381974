import { Resource, ResourceElement } from 'react-admin';
import Questions from './list';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { getResourcePath } from 'admin/pages-factory';


/**
 * Компонент страницы - Информации о вопросах пользователя
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const ClientQuestionsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку)
  return permissions
              ? <Resource name={getResourcePath('feedbacks')}
                          list={Questions} 
                          icon={FeedbackIcon}
                          options={{label: "Feedbacks"}} />
              : null;
}


export default ClientQuestionsPage;
