import { ResourceElement } from "react-admin";
import TranslateFeedbacksPage from "admin/pages/translate/feedbacks";

function buildTranslatePages(permissions: any): Array<ResourceElement> {
  const list = [
      TranslateFeedbacksPage(permissions),
   ].filter(item => item != null);
   return list as Array<ResourceElement>;
}

export default buildTranslatePages;
