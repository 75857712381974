import { Resource, ResourceElement } from 'react-admin';
import DocumentShow from './show';
import UserDocuments from './list';
import SubjectIcon from '@mui/icons-material/Subject';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Пользовательские документы
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const AnalyzedTextsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) пользовательские документы
  return permissions
              ? <Resource name= {getResourcePath('documents')}
                          list={UserDocuments}
                          show={DocumentShow}
                          icon={SubjectIcon}
                          options={{label: "Documents"}} />
              : null;
}


export default AnalyzedTextsPage;
