import { useState, useCallback, useEffect } from 'react';
import {
    Identifier,
    useDataProvider, 
    useNotify} from 'react-admin';

import { emptyParamList, Group } from 'admin/pages/types';
import { getResourcePath } from 'admin/pages-factory';

type Groups = Array<Group>;

const defaultInitPerPage = 100;

interface Props {
  defaultGroups: Groups;
  perPage: number;
}

interface GroupsForUserProps extends Partial<Props> {
  userId: Identifier | null
}

function useGroupsForUser(props: GroupsForUserProps) {
  const {userId, defaultGroups=[], perPage: initPerPage = defaultInitPerPage} = props;
  const [groups, setGroups] = useState<Groups>(defaultGroups);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initPerPage)
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updateGroups = useCallback (() => {
      if (userId !== null) {
        setLoading(true);
        return dataProvider.getList(getResourcePath('users', 'accounts'), {...params,  meta:{rootID: userId, subName: 'groups'} })
                .then(async (response) => {
                  setTotal(response.total ?? 0)
                  await setGroups(response.data);
                  return response.data;
                })
                .finally(() => {
                  setLoading(false);
                })
      }
    }, [dataProvider, userId, page, perPage]);

  const exitFromGroup = (groupId: number) => {
      setLoading(true);
      dataProvider.delete(getResourcePath('groups', 'accounts'), { meta:{rootID: groupId, subName: 'users'}, id: userId })
        .then(result => {
          updateGroups();
        });
    };

  const enterForGroup = (data: Group) => {
      setLoading(true);
      dataProvider.create(getResourcePath('groups', 'accounts'), { data: {id: userId}, meta:{rootID: data.id, subName: 'users'}})
        .then(() => {
          updateGroups();
        })
        .finally(() => {
          setLoading(false);
        })
  };

  useEffect(() => {
    updateGroups();
  }, [updateGroups]);
        
  return {groups, updateGroups, loading, exitFromGroup, enterForGroup, total, setPage, setPerPage, perPage};
}

interface UserGroupsProps extends Partial<Props> {
  excluded: Groups
}

export function useUserGroups(props: UserGroupsProps) {
  const {excluded=[], defaultGroups=[], perPage: initPerPage = defaultInitPerPage} = props;
  const [groups, setGroups] = useState<Groups>(defaultGroups);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initPerPage)
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updateGroups = useCallback((isOk: () => void) => {
    setLoading(true)
    return dataProvider.getList(getResourcePath('groups', 'accounts'), {...params })
      .then((response) => {
          const filtered = response.data.filter(item => !excluded.some(exclude => exclude.id === item.id))
          setTotal(response.total ?? 0)
          setGroups(filtered);
          isOk();
      })
      .catch(error => {
          notify(`Query groups list error: ${error.message}`, {type: 'error'});
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dataProvider, page, perPage, excluded])
  return {groups, loading, page, perPage, total, setPage, setPerPage, updateGroups}
}

export default useGroupsForUser;
