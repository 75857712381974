import React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

const DocumentShow: React.FC<any> = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <DateField source="date" label='Date'/>
            <TextField source="text" label='Text'/>
        </SimpleShowLayout>
    </Show>
);

export default DocumentShow;