import Loading from "components/loading";
import {useOcrPlans, useUserOcrSubscription} from "./hooks/ocr-plans";
import {RaRecord, useNotify, useRefresh, useResourceContext } from "react-admin";
import {
  Button,
  Autocomplete,
  Stack,
  TextField,
  FormControl} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, padding } from "@mui/system";


type RaProps = Omit<RaRecord, 'id'>;

const PropertiesPanel = (props: any) => {
  const {loading} = useOcrPlans();

  if (loading) return <Loading />;
  return (
    <>
      <SubscriptionsPanel {...props}/>
    </>
  )
}

interface Subscription {
  plan_name: string;
  start: string;
  end: string;
  page_count: number;
  page_count_max: number;
}

const SubscriptionsPanel: React.FC<RaProps> = props => {
  const userID = props.record.user_id;
  const record = props.record;
  const refresh = useRefresh();
  const notify = useNotify();
  const [newSubscription, setNewSubscription] = useState<Partial<Subscription>>(props.record)
  const {plans, loading} = useOcrPlans();
  const {changing, doChange} = useUserOcrSubscription(userID)
  const currentPlan = useMemo(() => {
    return plans.find(plan => plan.name === newSubscription.plan_name);
  }, [plans, loading, newSubscription.plan_name])

  const getPlanByName = useCallback((name: string | undefined) => {
    return name ? plans.find(plan => plan.name === name): undefined;
  }, [plans, loading])

  const canChange = useMemo(() => {
    const initSubscription: Subscription = record
    return (!loading && plans.length != 0 && !changing
        && ( newSubscription.page_count !== initSubscription.page_count
            || newSubscription.page_count_max !== initSubscription.page_count_max
            || newSubscription.plan_name !== initSubscription.plan_name
            || formatDate(newSubscription.end, true) !== initSubscription.end 
            || formatDate(newSubscription.start, true) !== initSubscription.start
           ))
  }, [changing, currentPlan,
      record.end, record.page_count, record.page_count_max, record.plan_name,record.start,
      newSubscription
    ]);
  
  
  if (loading) {
    return null;
  }
  

  return plans.length && currentPlan != null
    ? (
    <Box textAlign="center" m={1} maxWidth={500}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2}>
          <Autocomplete
              disabled = {plans.length === 0}
              value = {currentPlan ? {label: currentPlan.name} : null }
              id="user-ocr-subscription-plan"
              options={plans.map(({name}) => ({label: name}))}
              sx={{ minWidth: 300 }}
              size="medium"
              fullWidth={true}
              renderInput={(params) => <TextField {...params} label="Plan" />}
              onChange={(event, value, reason) => {
                  if (reason === 'selectOption') {
                    setNewSubscription({...newSubscription, plan_name: value?.label})
                  }
                  else {
                    setNewSubscription({...newSubscription, plan_name: value?.label})
                  }
                }}
              isOptionEqualToValue={(left, right) => left?.label === right?.label}/>
        </Stack>              
        <Stack direction='row' spacing={2} >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
            <DesktopDatePicker
              key={'Date-Start'}
              label="Start"
              inputFormat="DD.MM.YYYY"
              value={newSubscription.start}
              onChange={(date) => setNewSubscription({...newSubscription, start: formatDate(date as string)})}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              key={'Date-End'}
              label="End"
              inputFormat="DD.MM.YYYY"
              value={newSubscription.end}
              onChange={(date) => setNewSubscription({...newSubscription, end: formatDate(date as string)})}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction='row' spacing={2}>
          <TextField
              id="page-count"
              label="Page Count"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
              value={newSubscription.page_count}
              onChange={value => setNewSubscription({...newSubscription, page_count: parseInt(value.currentTarget.value ?? 0, 10)})}
            />
          <TextField
              id="page-max"
              label="Page Count Max"
              type="number"
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
              value={newSubscription.page_count_max}
              onChange={value => setNewSubscription({...newSubscription, page_count_max: parseInt(value.currentTarget.value ?? 0, 10)})}
            />
        </Stack>
        <Stack direction='row' spacing={2} alignSelf={"flex-end"}>
          <Button 
                disabled = {!canChange}
                variant="outlined"
                onClick={() => {
                  const patchData = {
                    plan_id: getPlanByName(newSubscription.plan_name)?.id,
                    term_start: formatDate(newSubscription.start, true),
                    term_end: formatDate(newSubscription.end, true),
                    page_count: newSubscription.page_count,
                    page_limit: newSubscription.page_count_max
                  }
                  doChange(patchData)
                  .then(result => {
                    if (result.isOk) {
                      notify(`Subscription for '${record.user_name}' was changed`);
                      refresh();
                    }
                    else {
                      notify(`Subscription change error for ${record.user_name}. Detail: ${result.messages.join('.')}`, {type: 'error'});
                    }
                  })
                }}>
            Save Changes
          </Button>
          <Button 
                disabled = {!canChange}
                variant="outlined"
                onClick={() => {
                  setNewSubscription(props.record)
                }}>
            Reset Changes
          </Button>
        </Stack>
      </Stack>
      </Box>)
  : null
}

const formatDate = (date: string | null | undefined, discardMilliseconds?: boolean, defValue?: string) =>  {
  const result = date 
   ? new Date(date).toJSON() 
    : defValue;
  return result && discardMilliseconds
    ? result.replace(/\.\d+/, "")
    : result; 
}

export default PropertiesPanel
