/**
 * Реализация отображение списка инофрмации по клиентам которые запрашивали загрузку тм-апи продукта
 */
import React, { cloneElement, useMemo, useState } from 'react';

import {
    Datagrid,
    DateField,
    ExportButton,
    FilterButton,
    List,
    Show,
    RaRecord,
    SimpleShowLayout,
    TextInput,
    TopToolbar,
    useRecordContext,
    BooleanField,
    BooleanInput,
    Title} from 'react-admin';

import { Box, Typography } from '@mui/material';
import TextViewField from 'components/text-view';
import Tablist from 'components/tabs/tab-list';
import SnapShotTextField from 'components/snapshot-text-field';
import ButtonLink from 'components/buttons/link';
import {DateRange as DateRangeInfo} from 'components/date-range/types';
import DateRanges from 'components/date-range/ra-date-range';
import StatisticsPanel from './statistics';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';
import { getResourcePath } from 'admin/pages-factory';


type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
    <TextInput source="dci" label = 'DCI' autoFocus/>,
    <DateRanges source='creation' label='Date'/>,
    <TextInput source="name" label = 'Name' autoFocus/>,
    <TextInput source="phone" label = 'Phone' type='phone' autoFocus/>,
    <TextInput source="email" label = 'Email' type='email' autoFocus/>,
    <TextInput source="question" label = 'Comment' autoFocus/>,
    <TextInput source="language" label = 'Language' autoFocus/>,
    <BooleanInput source="optOut" label = 'Opt out'/>
  ];
  return retList;
}

const DetailPanel: React.FC<RaProps> = props =>{
  const record = useRecordContext();
  return (
    <SimpleShowLayout>
        <TextViewField source="question" label = 'Question:'/>
        {record.optOut != null && <DateField source="optOut.date" label = 'Opt Out Date:'/>}
        {record.optOut != null && <TextViewField source="optOut.reason" label = 'Opt Out Reason:'/>}
        <ButtonLink label = 'Open Web Clients'
                redirectTo = 'list'
                pathTo = {getResourcePath('/clients')}
                filter = {{id: record.dci}} />
    </SimpleShowLayout>);
}

const ShowDetail: React.FC<RaProps> = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const Empty = () => {
  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            No clients questions
          </Typography>
      </Box>
  );
};

const ListActions: React.FC<RaProps> = (props) => {
  return (
    <TopToolbar>
        {props.filters && cloneElement(props.filters, { context: 'button' })}
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
  );
}

const ClientQuestions: React.FC<Props> = ({ permissions, ...props }) => {
  return (
    <>
      <List
            {...props}
            empty={<Empty />}
            title="Client questions"
            filters={getFilters(permissions)}
            sort={{ field: 'creation', order: 'DESC' }}
            perPage={25}
            actions={<ListActions/>}
            bulkActionButtons={false}>
        <Datagrid 
          expand={<ShowDetail {...props}/>}
          bulkActionButtons={false}>
            <SnapShotTextField source="dci" label = 'DCI'/>
            <DateField source="creation" label = 'Date'/>
            <SnapShotTextField source="name" label = 'Name' maxPrevLen={40}/>
            <SnapShotTextField source="phone" label = 'Phone'/>
            <SnapShotTextField source="email" label = 'Email'/>
            <SnapShotTextField source="question" label='Comment' maxPrevLen={100}/>
            <SnapShotTextField source="language" label = 'Language' maxPrevLen={40}/>
            <BooleanField source="optOut" label = 'Opt out' looseValue={true}/>
        </Datagrid>
      </List>
    </>
  );
}

const MainPanel: React.FC<Props> = (props) => {
  const {services} = useStoreContext();
  const [dateRange, setDateRange] = useState<DateRangeInfo | undefined>(undefined);
  const [active, setActiveTab] = React.useState<string>('Statistic');
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Feedbacks`, [services.activeId]);
  const listTab = [
      {
        label: 'Statistic',
        render: <StatisticsPanel dateRange={dateRange} onDateChanged={setDateRange}/>
      },
      {
        label: 'Data',
        render: <ClientQuestions {...props}/>
      }];
  return ( 
    <Box>
      <Title title={label} />
      <Tablist list={listTab} active={active} onActive={setActiveTab}/>
    </Box> );
}

export default observer(MainPanel);