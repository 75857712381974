import {RaRecord, useNotify, useRefresh, useResourceContext } from "react-admin";
import {
  Button,
  Autocomplete,
  Stack,
  TextField,
  FormControl} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetListParams, Identifier, useDataProvider } from "react-admin";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, padding } from "@mui/system";
import { AccessTokensRoute } from "providers/data-provider";
import { getResourcePath } from "admin/pages-factory";


type RaProps = Omit<RaRecord, 'id'>;

const PropertiesPanel = (props: any) => {
  return (
    <>
      <AccessTokensPanel {...props}/>
    </>
  )
}

interface DeleteAccessTokenResult {
  isOk: boolean;
  messages: Array<string>
}

interface AccessTokenState {
  changing: boolean;
  doDelete: () => Promise<DeleteAccessTokenResult>;
}

export function useAccessToken(tokenID: Identifier): AccessTokenState {
  const dataProvider = useDataProvider();
  const [changing, setChanging] = useState(false);
  const doDelete = () => {
    setChanging(true);
    return dataProvider.delete(getResourcePath(AccessTokensRoute.AccessTokens), {id: tokenID})
            .then(response => {
              const result: DeleteAccessTokenResult = {isOk: !response.data.errors || !Array.isArray(response.data.errors) || !response.data.errors.length, messages: response.data.message ?? []}
              if (!result.isOk) {
                interface ErrorDto {
                  id: number;
                  message: string
                }
                interface ErrorsDto {
                  errors?: Array<ErrorDto>
                }
                const data: ErrorsDto = response.data
                if (data.errors)  {
                  result.messages = data.errors.map(error => error.message)
                }
              }
              return result;
            })
            .finally(() => {
              setChanging(false);
            })
  };

  return {changing, doDelete}
}

const AccessTokensPanel: React.FC<RaProps> = props => {
  const tokenID = props.record.id;
  const record = props.record;
  const refresh = useRefresh();
  const {changing, doDelete} = useAccessToken(tokenID)
  const notify = useNotify();

  return (
    <Box textAlign="center" m={1} maxWidth={500}>
      <Stack direction='column' spacing={2}>
          <Button 
                disabled = {changing}
                variant="outlined"
                onClick={() => {
                  doDelete()
                  .then(result => {
                    if (result.isOk) {
                      notify(`Access token for '${record.user_name}' was deleted`);
                      refresh();
                    }
                    else {
                      notify(`Error when deleting access token for ${record.user_name}. Detail: ${result.messages.join('.')}`, {type: 'error'});
                    }
                  })
                }}>
            Delete Token
          </Button>
      </Stack>
    </Box>)
}

export default PropertiesPanel
