/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React from 'react';
import {
    List,
    Show,
    SimpleShowLayout,
    TextField,
    useRefresh,
    useRecordContext,
    useNotify,
    RaRecord} from 'react-admin';
import { Button } from "@mui/material"; 
import UsersAtGroup from './users-datagrid';
import useUsersAtGroups from './hooks/users';
import PermissionAtGroup from './permissions-datagrid';
import Datagrid from 'components/ra-data-grid';
import UserAtGroupContext from './hooks/store-context';
    
const Loading = () => {
  return <>Loading ...</>
}

const DetailPanel = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = props.record as RaRecord;
  const context = useUsersAtGroups(record.id);
  const {users, loading, deleteGroup} = context;
  if (loading) return <Loading />;
  if (!users) return null;

  return (
    <>
      <UserAtGroupContext.Provider value={context}>
        <Button 
              disabled = {users.length !== 0} 
              variant="outlined"
              onClick={() => {
                deleteGroup()
                .then(() => {
                  notify(`Group '${record.name}' was deleted`);
                  refresh();
                })
                .catch(error => {
                  notify(`Group '${record.name}' deletion error: ${error.message}`, {type: 'error'});                      
                });
              }}>
          Delete Group
        </Button>
        <UsersAtGroup {...props}/>
        <PermissionAtGroup {...props}/>
      </UserAtGroupContext.Provider >
    </>);
}

type RaProps = Omit<RaRecord, 'id'>;

const ShowDetail: React.FC<RaProps> = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const GroupsList: React.FC = () => {
  //const { permissions } = usePermissions();
  return <List  title="List Of User Groups"
                bulkActionButtons={false} >
            <Datagrid bulkActionButtons={false}
                      expand={<ShowDetail />}
                      expandSingle>
                <TextField source="name" label="Group Name"/>
            </Datagrid>
          </List>
}

export default GroupsList;
