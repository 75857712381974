import React from 'react';
import WorldMap from 'react-svg-worldmap';
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/material';
import { CountPerField as CountryInfo } from '../statistics-response';
import DataTable, { CountPerField, LinkGenerator } from 'components/static-data-grid/data-table';
import TableCell from '@mui/material/TableCell';
import { FlagIcon,
         FlagIconCode }       from 'react-flag-kit';
import { OnLinkClick } from 'components/buttons/link';
import { GtLteDate } from 'components/date-range/types';
import { getResourcePath } from 'admin/pages-factory';


const useStyles = makeStyles((theme) => ({
  content: {
    padding: '10px'
  }
}));

interface Props {
  resource: Array<CountryInfo>
}

const CountryMap: React.FC<Props> = (props) => {
  const dataView = props.resource.map( ({id, count}) => {
      return {
         country: id,
         value: count
      }
    }).filter(data => data.country != null);
  const classes = useStyles();
  return dataView 
    ? (
      <Box className={classes.content}>
        {<WorldMap
          color="blue"
          title="Geographic Location"
          value-suffix="people"
          size='responsive'
          strokeOpacity={0.3}
          data={dataView}
          tooltipTextFunction={context => {
            return `${context.countryName} (${context.countryValue})`
          }}
        />}
      </Box>
    )
    : null;
}

const heads = [
  {
    id: 'fullName',
    label: `Country`
  },
  {
    id: 'count',
    label: `Clients`,
    isNumeric: true
  },
  {
    id: 'precentage',
    label: `Clients, %`,
    isNumeric: true
  }
]

interface TableProps {
  resource: Array<CountPerField>;
  filter: Record<string, GtLteDate>;
  onLink: OnLinkClick;
}

export const CountryTable:React.FC<TableProps> = props => {
  const preColumn = (country: string, index: number, children: React.ReactNode) => {
      const flag = country && country.length > 0
            ? (<FlagIcon size={22} style={{ marginRight: '6px'}} code={country as FlagIconCode} />)
            : undefined;
      return (
          <TableCell component="th" scope="row">
              {index}. {flag} {children}
          </TableCell>)
    };
  const linkGenerator: LinkGenerator = (id: string) => {
      return {
          redirectTo: 'list',
          pathTo: getResourcePath('/clients'),
          filter: {country: id, ...props.filter}
        };
      }
  

  return <DataTable 
            resource={props.resource}
            idLabelKey={'fullName'}
            heads={heads}
            sortBy='count'
            preColumn = {preColumn}
            linkGenerator={linkGenerator}
            onLink={props.onLink}/>
}

export default CountryMap;