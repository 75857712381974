import axios, { AxiosInstance } from 'axios';
import urls from 'connections/urls';


export class AdminPortalProvider {

  protected readonly http: AxiosInstance
  constructor() {
    this.http = axios.create({validateStatus: status => (status >= 200 && status <=300)});
  }

  async getServices<T>(): Promise<T> {
    const {data} = await this.http.get(urls.getBack('/services'));
    return data;
  }
}

const adminInstance = new AdminPortalProvider();

export default adminInstance;