export enum ServiceTypes {
  Accounts = 'Accounts',
  Demo = 'Demo',
  OCR = 'OCR',
  Compare = 'Compare',
  ChatGptMessages = 'Messages',
  Translate = 'Translate'
}

export interface ServiceDesc {
  type: ServiceTypes,
}

export interface ServicesItems {
  [name: string]: ServiceDesc;
}

export interface ServiceUrls {
  gatewayUrl: string;
  loginUrl: string;
  backend?: string;
}

export interface Services {
  urls: ServiceUrls;
  items: ServicesItems;
}
