export class NotImplement extends Error {
  constructor(what: string) {
    super(`Not implemented call for ${what}`);
  }
}

export class UnInitializeResources extends Error {
  constructor(what: string, private records: Record<string, any>) {
    super(`Empty gateway url`)
  }
}

