import axios, {AxiosInstance} from 'axios';
import { stringify } from 'query-string';
import { DataProvider } from 'ra-core';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 * 
 * export default App;
 */
const provider = (
    apiUrl: string,
    httpClient: AxiosInstance = axios.create(),
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: {
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }
                  }
                : {};
        return httpClient.get(url, options).then(({ headers, data }) => {
            const contentRange = headers[countHeader.toLowerCase()];
            if (!contentRange) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            const rangeValue = contentRange !== undefined ? contentRange.split('/').pop(): ''
            return {
                data,
                total:
                    countHeader === 'Content-Range'
                        ? rangeValue !== undefined? parseInt(rangeValue, 10): 0
                        : contentRange !== undefined? parseInt(contentRange) : 0,
            };
        });
    },

    getOne: (resource, params) =>
        httpClient.get(`${apiUrl}/${resource}/${params.id}`).then(({ data }) => ({
            data
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient.get(url).then(({ data }) => ({ data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: {
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }
                  }
                : {};

        return httpClient.get(url, options).then(({ headers, data }) => {
            const contentRange = headers[countHeader.toLowerCase()];
            if (!headers[countHeader.toLowerCase()]) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            const rangeValue = contentRange !== undefined ? contentRange.split('/').pop(): ''
            return {
                data,
                total:
                    countHeader === 'Content-Range'
                        ? rangeValue !== undefined? parseInt(rangeValue, 10): 0
                        : contentRange !== undefined? parseInt(contentRange) : 0,
            };
        });
    },

    update: (resource, params) =>
        httpClient.put(`${apiUrl}/${resource}/${params.id}`, params.data)
                  .then(({ data }) => ({ data })),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient.put(`${apiUrl}/${resource}/${id}`, params.data)
            )
        ).then(responses => ({ data: responses.map(({ data }) => data.id) })),

    create: (resource, params) =>
        httpClient.post(`${apiUrl}/${resource}`, params.data)
                  .then(({ data }) => ({
                        data: {
                          ...params.data,
                          id: data.id 
                        }
                    })),

    delete: (resource, params) =>
        httpClient.delete(
            `${apiUrl}/${resource}/${params.id}`,
            {
              headers: {
                'Content-Type': 'text/plain',
              }
            })
            .then(({ data }) => ({ data })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient.delete(`${apiUrl}/${resource}/${id}`, {
                    headers: {
                        'Content-Type': 'text/plain',
                    }
                })
            )
        ).then(responses => ({
            data: responses.map(({ data }) => data.id),
        })),
});

export default provider;
