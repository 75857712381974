import { useStoreContext } from 'admin/store-context';
import React, { useMemo } from 'react';
import {
  BooleanField,
  Datagrid, DateField,
  EditButton,
  List,
  ShowController,
  SimpleShowLayout,
  TextField,
  TextInput,
  ShowView,
  RaRecord
} from 'react-admin';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import NotificationTest from "./test";

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const getNotificationFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] =
    [
      <TextInput source="title" alwaysOn />,
      <TextInput source="webhookUrl" autoFocus/>,
      <TextInput source="period" autoFocus/>,
    ];
  return retList;
}

const NotificationPanel: React.FC<RaProps> = props => {
  return <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps} title=" ">
        <SimpleShowLayout>
          <DateField source="createdAt" label='Created At'/>
          {controllerProps.record && controllerProps.record.modifiedAt && <DateField source="modifiedAt" label='Modified At'/>}
          {controllerProps.record && controllerProps.record.activateAt && <DateField source="activateAt" label='Activate At'/>}
          {controllerProps.record && controllerProps.record.lastSuccessSendAt && <DateField source="lastSuccessSendAt" label='Last Success Send At'/>}
          <NotificationTest id={props.id}/>
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
};

const NotificationList: React.FunctionComponent<Props> = ({ permissions, ...props }) => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Notifications`, [services.activeId]);
  return (
    <List
      {...props}
      title = {label}
      filters={getNotificationFilters(permissions)}
      sort={{field: 'title', order: 'ASC'}}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid 
        expand={<NotificationPanel />}
        bulkActionButtons={false}>
          <TextField source="title"/>
          <TextField source="webhookUrl"/>
          <TextField source="period"/>
          <BooleanField source="activateAt" label="Activated" looseValue={true}/>
          <EditButton/>
      </Datagrid>
    </List>
  )
};

export default NotificationList;
