import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useCallback, useState } from 'react';
import { RaRecord, useRecordContext } from 'react-admin';
import { useRefresh } from 'react-admin';
import useUser from './hooks/users';
import { UserInfo } from "admin/pages/types";

function prePatch<T>(userInfo: UserInfo, name: keyof UserInfo, value: T, preProcess?: (value: T) => T):  UserInfo {
  const prevValue = userInfo[name];
  let newValue = prevValue;
  if (typeof value === 'boolean') {
    newValue = preProcess? preProcess(value) as boolean: value ;
  }
  return {
    ...userInfo,
    [name]: newValue,
  }
}

const UserProperties = (props: any) => {
  const record: RaRecord = props.record;
  const refresh = useRefresh();
  const {patchUser} = useUser(record.id);
  const {id, ...other} = record
  const [properties, setProperties] = useState<UserInfo>({
      id: +record.id,
      username: record.username,
      email: record.email,
      verified_email: record.verified_email,
      deleted: record.deleted
    });
  const updateProperties = useCallback((newProperties: UserInfo) => {
    patchUser(newProperties, properties)
    .then(() => {
      setProperties({
        ...newProperties
      });
      refresh();
    });

  }, [properties, refresh])

  // return (<div>
  //   <div>User properties</div>
  //   <div>{properties.username}</div>
  //   <div>{properties.email}</div>
  // </div>)

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">User properties</FormLabel>
      <FormGroup>
      <FormControlLabel
          control={
            <Switch checked={properties.verified_email} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newProperties = prePatch(properties, (event.target.name as keyof UserInfo), event.target.checked);
                      updateProperties(newProperties)
                    }} 
                    name="verified_email" />
          }
          label='Verified Email'
        />
      <FormControlLabel
          control={
            <Switch checked={!properties.deleted} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newProperties = prePatch(properties, (event.target.name as keyof UserInfo), event.target.checked, value => !value);
                      updateProperties(newProperties)
                    }} 
                    name="deleted" 
                    defaultChecked={false}/>
          }
          label='Enabled'
        />

      </FormGroup>
    </FormControl>
  )
}

export default UserProperties;