import { Resource, ResourceElement } from 'react-admin';
import EditItem from './edit';
import LanguageSupports from './list';
import CreateForm from './create';
import Icon from '@mui/icons-material/Language';
import { getResourcePath } from 'admin/pages-factory';


/**
 * Компонент страницы - настройки бэка
 * @param permissions - полученные права доступа
 * @returns - React.Node[]
 */
 const SettingsPage = (permissions: any): ResourceElement | null => {
  return (
          <Resource name={getResourcePath('config/supported-languages')}
                    options={
                      {
                        label: "Languages",
                        //menuParent: "settings"
                       }
                    }
                    list={LanguageSupports}
                    edit={EditItem}
                    create={CreateForm}
                    icon={Icon} />);
}


export default SettingsPage;
