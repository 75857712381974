import httpConnector from "connections/api";
import urls from "./urls";

async function sendAction<T, R>(url: string, body: T): Promise<R> {
  const result = await httpConnector.post(url, body);
  return result.data;
}
export async function getNotificationTest(projectName: string, id: string) {
  const url = urls.getDemo({projectName, path: `/config/notifications/${id}/test`});
  return (await sendAction(url, {withoutSend: false}));
}
