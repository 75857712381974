import { ResourceElement } from "react-admin";
import CompareHistoryPage from "admin/pages/compare/history";

function buildComparePages(permissions: any): Array<ResourceElement> {
  const list = [
      CompareHistoryPage(permissions),
   ].filter(item => item != null);
   return list as Array<ResourceElement>;
}

export default buildComparePages;
