import * as exceptions from 'utilities/errors/common';
import { clearCookies, getCookieToken } from './auth-cookies';
/**
 * Модуль управления JWT-токенами
 */

// таг токена в хранилище для доступа
const accessTokenTag = `access-token`;

abstract class AbstractTokenManager {

  constructor(public readonly tokenTag: string){}

  get token(): string {
    throw new exceptions.NotImplement(`${AbstractTokenManager.name}:token()`);
  }

  set token(token: string) {};

  eraseToken() {};
}

/**
 * Базовая реализация JWT-манагера (доступ, сохранение, удаление)
 */
class JwtManager extends AbstractTokenManager {

  constructor(tokenTag: string = accessTokenTag) {
    super(tokenTag)
  }
  
  // доступ к токену
  get token(): string {
    let token = localStorage.getItem(this.tokenTag);
    if (!token) {
      token = getCookieToken()?? null;
    }
    return token ?? '';
  }

  // установка токена
  setToken(token: string) {
    localStorage.setItem(accessTokenTag, token);
  }
  
  // удаление токена
  eraseToken() {
    localStorage.removeItem(accessTokenTag);
    clearCookies();
  }
}

export default JwtManager;