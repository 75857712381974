import { getResourcePath } from "admin/pages-factory";
import { UserInfo } from "admin/pages/types";
import { Method } from "providers/common-provider";
import { useCallback, useState } from "react";
import { Identifier, useDataProvider } from "react-admin";

function useUser(userId: Identifier) {
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();

  const patchUser = useCallback ((data: Partial<UserInfo>, previousData?: Partial<UserInfo> ) => {
    setLoading(true);
    return dataProvider.update(getResourcePath('users', 'accounts'), {id: userId, data, previousData: previousData ?? data, meta: {method: Method.patch}})
            .finally(() => {
              setLoading(false);
            })
  }, []);

  return {loading, patchUser};
}

export default useUser;
