import { Admin, DataProvider, useNotify } from 'react-admin';
import authProvider from 'providers/auth-provider';
import getProvider from 'providers/data-provider';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import ServicesPage from './pages/services';
import { MainLayout } from './layout/main-layout';
import { ServicePagesFactory } from './pages-factory';
import mainStore from './store-instance';

const App = () => {
  const {services} = mainStore;
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);
  const notify = useNotify();  
  const activeService = services.active?.type;

  useEffect(()=> {
    try {
      const dataProvider = getProvider(services.gatewayUrl, services.activeId??'');
      setDataProvider(dataProvider);
    }
    catch (error) {
      let msg = `Unknown data provider`; 
      if (error instanceof Error) {
        msg += `Detail: ${error.message}`;
      }
      notify(`Unknown data provider. Detail: ${msg}`, {type: 'error'})
    }
  }, [services.activeId, services.gatewayUrl, notify])

  const servicePageBuilder = useCallback((permissions: any) => {
    let servicePage = ServicesPage(permissions);
    const pages = activeService 
            ? ServicePagesFactory.createPages(activeService, permissions)
            : null;
    if (pages) {
      return [servicePage, ...pages]
    }
    else {
      return [servicePage];
    }
  }, [activeService, services.activeId]);

  const loading = !(dataProvider && services.items);
  return (
    <>
    {!loading
      && (
          <Admin
              layout={MainLayout} 
              dataProvider={dataProvider}
              authProvider={authProvider}>
            {
              servicePageBuilder
            }
          </Admin>
        )}
    {loading && <>Wait...</>}
    </>)
};

export default observer(App);