import { FC, memo } from "react";
import { RichTextFieldProps, useListContext, useRecordContext } from "react-admin";
import get from 'lodash/get';
import { highlightFilteredText } from "components/text-utils";
import { Typography } from '@mui/material';

interface Props extends RichTextFieldProps {
  addLabel?: boolean
}

const sanitizeFieldRestProps: (props: any) => any = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  emptyText,
  formClassName,
  fullWidth,
  headerClassName,
  label,
  linkType,
  link,
  locale,
  record,
  refetch,
  resource,
  sortable,
  sortBy,
  sortByOrder,
  source,
  textAlign,
  translateChoice,
  ...props
}) => props;

const TextViewField: FC<Props> = memo<Props>(
  props => {
      const { className, emptyText, source, stripTags, ...rest } = props;
      const record = useRecordContext(props);
      const value = source ? get(record, source): '';
      const { filterValues } = useListContext();
      return (
          <Typography
              className={className}
              variant="body2"
              component="span"
              {...sanitizeFieldRestProps(rest)}
          >
            {highlightFilteredText(value, filterValues.text)}
          </Typography>
      );
  }
);

TextViewField.defaultProps = {
  addLabel: true
};


export default TextViewField;
