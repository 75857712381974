/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    TextField,
    Show,
    NumberField,
    RaRecord,
    TextInput} from 'react-admin';
import DateRanges from 'components/date-range/ra-date-range';    


const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
    <TextInput source="api_key" label='API Key'/>,
    <TextInput source="user_name" label='User Name'/>,
    // <DateRanges source="created_at" label = 'Create At'/>,
    // <DateRanges source="modified_at" label = 'Modified At'/>
  ];
  return retList;
}

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const ApiKeysList: React.FC<Props> =  ({ permissions, ...props }) => {
  return <List
                title="API Keys"
                perPage={25}
                sort={{ field: 'modified_at', order: 'DESC' }}
                filters={getFilters(permissions)}
                bulkActionButtons={false}
                {...props}
                >
            <Datagrid bulkActionButtons={false}>
                <TextField source="user_name" label='User Name'/>
                <TextField source="api_key" label='API Key'/>
                <DateField source="created_at" label='Create At'/>
                <DateField source="modified_at" label='Modified At'/>
                <NumberField source="deployment_id" label='Deployment ID'/>
            </Datagrid>
          </List>
}

export default ApiKeysList;
