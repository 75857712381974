import { getResourcePath } from "admin/pages-factory";
import { UserInfo } from "admin/pages/types";
import { Method } from "providers/common-provider";
import { AccountsRote, BillingRoute } from "providers/data-provider";
import { useCallback, useEffect, useState } from "react";
import { GetListParams, Identifier, useDataProvider } from "react-admin";

function defaultPagination(): GetListParams {
  return {
    pagination: {
      page: 0,
      perPage: 0
    },
    sort: {
      field: 'page_limit',
      order: 'ASC'
    },
    filter: {
    }
  }
}

interface OcrPlan {
  id: Identifier;
  name: string;
  page_limit: number;
}

interface OcrPlansState {
  plans: Array<OcrPlan>;
  loading: boolean;
}

export function useOcrPlans(): OcrPlansState {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Array<OcrPlan>>([]);
  const dataProvider = useDataProvider();

  const getPlans = () => {
    setLoading(true);
    return dataProvider.getList<OcrPlan>(getResourcePath(BillingRoute.Plans, 'accounts'), defaultPagination())
            .then(newPlans => {
              newPlans.data
              setPlans(newPlans.data)
            })
            .finally(() => {
              setLoading(false);
            })
  };

  useEffect(() => {
    getPlans()
  }, [])
  return {loading, plans};
}

interface OcrUserSubscription {
  plan_id: Identifier;
  term_id: string;
  term_start: string;
  term_end: string;
  page_count: number;
}

interface ChangedSubscriptionResult {
  isOk: boolean;
  messages: Array<string>
}

interface OcrUserSubscriptionState {
  changing: boolean;
  doChange: (value: Partial<OcrUserSubscription>) => Promise<ChangedSubscriptionResult>;
}

export function useUserOcrSubscription(userID: Identifier): OcrUserSubscriptionState {
  const dataProvider = useDataProvider();
  const [changing, setChanging] = useState(false);
  const doChange = (value: Partial<OcrUserSubscription>) => {
    setChanging(true);
    return dataProvider.update(getResourcePath(AccountsRote.Users, 'accounts'), {id: 0, data: value, previousData: value, meta:{discardID: true, rootID: userID, method: Method.patch, subName: BillingRoute.Subscription}})
            .then(response => {
              const result: ChangedSubscriptionResult = {isOk: !response.data.errors || !Array.isArray(response.data.errors) || !response.data.errors.length, messages: response.data.message ?? []}
              if (!result.isOk) {
                interface ErrorDto {
                  id: number;
                  message: string
                }
                interface ErrorsDto {
                  errors?: Array<ErrorDto>
                }
                const data: ErrorsDto = response.data
                if (data.errors)  {
                  result.messages = data.errors.map(error => error.message)
                }
              }
              return result;
            })
            .finally(() => {
              setChanging(false);
            })
  };

  return {changing, doChange}
}