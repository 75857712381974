import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'admin/app';
import { StoreContextProvider } from 'admin/store-context';
import mainStore from 'admin/store-instance';

async function bootstrap() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  try {
    await mainStore.services.update();
    root.render(
      <React.StrictMode>
        <StoreContextProvider value={mainStore}>
          <App />
        </StoreContextProvider>      
      </React.StrictMode>
    );
  }
  catch(error) {
    console.error(`Can't load services data`, {error})
  }
}

bootstrap();

