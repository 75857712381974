/**
 * Провайдер аутентификации
 */

import { AuthProvider } from "react-admin";
import backend, { jwtManager } from 'connections/auth';
import axios, { AxiosError } from "axios";
import mainStore from 'admin/store-instance';


interface UserIdentity {
  id: string;
  fullName: string;  
}

let userIdentify: UserIdentity | null = null;

interface LoginParam {
  username: string;
  password: string
}

interface LoginErrorDesc {
  id: string;
  message: string;
}

/**
* Аутентификация пользователя (с последующим сохранением данных о нем в браузере (как текущего))
* @param param - логин и пароль пользователя 
* @returns 
*/
const doLogin = async ({ username, password }: LoginParam) => {
  try {
    const token = await backend.login(username, password);
    jwtManager.setToken(token);
    return token;
  }
  catch(error) {
    let message = (error as any).message;
    if (axios.isAxiosError(error)) {
      const errors: Array<LoginErrorDesc> = error.response?.data?.errors;
      message = errors?.map(item => item.message).join('\n') ?? error.message;
    }
    return Promise.reject({message});
  }
}

/**
* Пользователь вышел из системы
* @returns 
*/
const doLogout = (): Promise<any> => {
  const redirectToLogin = () => {
    let redirect: string | undefined = undefined;
    if (mainStore.services.loginUrl) {
      const url = new URL(mainStore.services.loginUrl);
      url.searchParams.append('return_to', window.location.href);
      redirect = url.href;
    }
    return Promise.resolve(redirect);
  }

  if (jwtManager.token !== '') {
    backend.logout()
      .catch(() => redirectToLogin())
      .finally(() => jwtManager.eraseToken());
  }
  return redirectToLogin();
}

/**
* Проверка - аутентифицирован ли пользователь перед какой-либо операцией
* @returns 
*/
const checkAuth = (): Promise<any> => {
  const redirectToLogin = (message: string = 'Required login') => {
      return Promise.reject({ message } );
    }
  if (jwtManager.token !== '') {
    return backend.checkToken(jwtManager.token)
      .then(({id, username}) => {
        userIdentify = {id, fullName: username};
        return Promise.resolve(userIdentify);
      })
      .catch(error => {
          userIdentify = null;
          return redirectToLogin();
      })
  }

  return redirectToLogin()
}
const checkError = (error: any | AxiosError): Promise<any> => {
  if (axios.isAxiosError(error)) {
    const {response} = error;
    const status = response?.status
    if (status === 401 || status === 403) {
      jwtManager.eraseToken();
      const errors: Array<LoginErrorDesc> = error.response?.data?.errors;
      const message = errors?.map(item => item.message).join('\n') ?? error.message;
      return Promise.reject({ redirectTo: '/login', message});
    }
    else if (!status || status >= 400) {
      const errors: Array<LoginErrorDesc> = error.response?.data?.errors;
      const message = errors?.map(item => item.message).join('\n') ?? error.message;
      return Promise.reject({message, logoutUser: false});
    }
  }
  return Promise.resolve();
}

/**
* Основной объект аутентификации пользователя
*/
const defaultAuth: AuthProvider = {
  // действие на логин
  login: doLogin,
  // действие на логаут
  logout: doLogout,
  // проверка аутентификации
  checkAuth: checkAuth,
  // проверка на какую либо  ошибку от бэкэнда
  checkError: checkError,
  // проверка на права пользователя
  getPermissions: () => {
    return Promise.resolve('administrator');
  },
  getIdentity: () => {
    if (jwtManager.token !== '' && userIdentify) {
      return Promise.resolve(userIdentify)
    }
    return Promise.reject();
  }
};

export default defaultAuth;

