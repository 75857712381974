import { Resource, ResourceElement } from 'react-admin';
import NotificationList from "./list";
import NotificationCreate from "./create";
import NotificationEdit from "./edit";
import NotificationShow from "./show";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { getResourcePath } from 'admin/pages-factory';


const NotificationsPage = (permissions: any): ResourceElement | null => {
  return permissions
    ? <Resource name={getResourcePath('config/notifications')}
                list={NotificationList}
                create={NotificationCreate}
                edit={NotificationEdit}
                show={NotificationShow}
                options={{label: "Notifications"}}
                icon={NotificationImportantIcon}
    />
    : null;
}


export default NotificationsPage;
