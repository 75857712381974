import { useState, useCallback, useEffect} from 'react';
import {
    Identifier,
    useDataProvider, 
    useNotify} from 'react-admin';

import { emptyParamList, UserInfo } from 'admin/pages/types';
import { getResourcePath } from 'admin/pages-factory';

type Users = Array<UserInfo>;

const defaultInitPerPage = 100;

export interface IUserContext {
  users: Users;
  loading: boolean;
  page: number;
  perPage: number;
  total: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  updateUsers: () => Promise<any>;
  deleteUser: (idUser: number) => Promise<any>;
  addUser: (data: UserInfo) => Promise<any>;
  deleteGroup: () => Promise<any>;
}

function useUsersAtGroups(groupId: Identifier, defaultUsers: Users = []): IUserContext {
  const [users, setUsers] = useState<Users>(defaultUsers);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5)
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updateUsers = useCallback (() => {
      setLoading(true);
      return dataProvider.getList(getResourcePath('groups', 'accounts'), { ...params,  meta:{rootID: groupId, subName: 'users'} })
              .then(async (response) => {
                setTotal(response.total ?? 0)
                await setUsers(response.data);
                return response.data;
              })
              .finally(() => {
                setLoading(false);
              })
    }, [dataProvider, groupId, page, perPage]);

  const deleteUser = (idUser: number) => {
      setLoading(true);
      return dataProvider.delete(getResourcePath('groups', 'accounts'), { meta:{rootID: groupId, subName: 'users'}, id: idUser })
                .then(result => {
                  return updateUsers();
                })
                .finally(() => {
                  setLoading(false);
                });
    };

  const addUser = (data: UserInfo) => {
      setLoading(true);
      return dataProvider.create(getResourcePath('groups', 'accounts'), { data: data, meta:{rootID: groupId, subName: 'users'}})
              .then(() => {
                return updateUsers();
              });
  };

  const deleteGroup = async () => {
    try {
      setLoading(true);
      await dataProvider.delete(getResourcePath('groups', 'accounts'), { id: groupId});
      await updateUsers();
    }
    catch(error) {
      return Promise.reject(error);
    }
    finally {
      setLoading(false);
    }
    return Promise.resolve();
};


  useEffect(() => {
      updateUsers();
    }, [updateUsers]);
        
  return {users, updateUsers, loading, deleteUser, addUser, deleteGroup, page, perPage, total, setPage, setPerPage};
}

interface Props {
  defaultUsers: Users;
  perPage: number;
}

interface UsersProps extends Partial<Props> {
  excluded: Users
}

export function useUsers(props: UsersProps) {
  const {excluded=[], defaultUsers=[], perPage: initPerPage = defaultInitPerPage} = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [users, setUsers] = useState<Users>(defaultUsers);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initPerPage)
  const [total, setTotal] = useState(0);
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updateUsers = useCallback((isOk: () => void) => {
    setLoading(true)
    dataProvider.getList(getResourcePath('users', 'accounts'), {...params })
    .then((response) => {
        setTotal(response.total ?? 0)
        const filtered = response.data.filter(item => !excluded.some(exclude => exclude.id === item.id))
        setUsers(filtered);
        isOk();;
    })
    .catch(error => {
        notify(`Query user list error: ${error.message}`, {type: 'error'});
    })
    .finally(() => {
      setLoading(false);
    })
  }, [dataProvider, page, perPage, excluded])
  return {users, loading, page, perPage, total, setPage, setPerPage, updateUsers};
}

export default useUsersAtGroups;
