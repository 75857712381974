import React from 'react';
import { CountPerField } from '../statistics-response';
import DataTable, { LinkGenerator } from 'components/static-data-grid/data-table';
import { OnLinkClick } from 'components/buttons/link';
import { getResourcePath } from 'admin/pages-factory';

const heads = [
  {
    id: 'id',
    label: `City`
  },
  {
    id: 'count',
    label: `Clients`,
    isNumeric: true
  },
  {
    id: 'precentage',
    label: `Clients, %`,
    isNumeric: true
  }
]


interface Props {
  resource: Array<CountPerField>;
  filter?: Record<string, any>;
  onLink: OnLinkClick;
}

const CityTable: React.FC<Props> = props => {
  const linkGenerator: LinkGenerator = (id: string) => {
    return {
        redirectTo: 'list',
        pathTo: getResourcePath('/clients'),
        filter: props.filter ? {city: id, ...props.filter}: {city: id}
      };
    }

  return <DataTable
            resource={props.resource}
            heads={heads}
            sortBy='count'
            linkGenerator={linkGenerator}
            onLink={props.onLink} />
}

export default CityTable;