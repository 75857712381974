import { Resource, ResourceElement } from 'react-admin';
import list from './pdfs-list';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Списки пдф страниц пользователей
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const OcrPdfsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку)
  return permissions
              ? <Resource name= {getResourcePath('pdfs_history', 'ocr')}
                          list={list}
                          icon={PictureAsPdfIcon}
                          options={{label: "PDFs"}} />
              : null;
}


export default OcrPdfsPage;
