import { Menu, useResourceDefinitions } from 'react-admin';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

export const NavigateMenu = () => {
  const resources = useResourceDefinitions();
  return (
    <Menu>
        <Menu.Item to="/services" primaryText="Services" leftIcon={< MiscellaneousServicesIcon />}/>
        {Object.keys(resources).map(name => (
                <Menu.ResourceItem key={name} name={name} />
            ))}
    </Menu>);
};