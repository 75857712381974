import { Resource, ResourceElement } from 'react-admin';
import Edit from './edit';
import List from './list';
import Icon from '@mui/icons-material/Settings';
import { getResourcePath } from 'admin/pages-factory';

 const BackendSettingsPage = (permissions: any): ResourceElement | null => {
  return (
          <Resource name={getResourcePath('config/settings')}
                    options={
                      {
                        label: "Settings",
                       }
                    }
                    list={List}
                    edit={Edit}
                    icon={Icon} />);
}


export default BackendSettingsPage;
