import { useStoreContext } from "admin/store-context";
import { observer } from "mobx-react-lite";
import { Card, CardContent, CardHeader, Box, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { Title, useAuthenticated } from "react-admin";
import { ServicesItems } from "models/services/types";

const ServicesList: React.FC = () => {
  const {services} = useStoreContext();
  useAuthenticated({params: {service: services.activeId}});
  return (
    <Card sx={{ display: 'flex' }}>
      <Title title="Common Services" />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardHeader title="Switch to service" />
          <CardContent>
            <Selector 
                services={services.items?? {}} 
                current={services.activeId??''} 
                onChange={(value: string | null) => {
                  services.activeId = value
                } } />
          </CardContent>
      </Box>
    </Card>)
}

interface Props {
  services: ServicesItems;
  current: string | null;
  onChange: (value: string | null) => void;
}

const Selector: React.FC<Props> = ({current, services, onChange}) => {

  const menuList = Object.entries(services)
                    .map(([key, value]) => {
                      return (<MenuItem value={key} key={key}>{key}</MenuItem>)
                    })
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Service</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={current}
        label="Service"
        onChange={(event) => onChange(event?.target?.value)}
      >
        {menuList}
      </Select>
    </FormControl >);
}

export default observer(ServicesList);