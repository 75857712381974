import React, { useEffect, useState } from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRange, roundDate, shiftDay } from './types';
import locale from 'date-fns/locale/en-US';
import RangePreset, {Option as RangeOption} from './presets';
import { makeStyles } from "@mui/styles";

export enum DateType {
  creation = 'creation',
  update = 'update'
};

const useStyles = makeStyles((theme) => ({
  boxContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  boxAlignLeft: {
    marginRight: 'auto'
  },
  datePickers: {
    display: 'flex',
  },
  datePickerSpace: {
    width: '20px'
  }
}));

const dateTypes: Array<RangeOption<DateType>> = [
  {
    key:'creation',
    title: 'Created',
    getRange: () => DateType.creation
  },
  {
    key:'update',
    title: 'Updated',
    getRange: () => DateType.update
  }
];

const Ranges: Array<RangeOption<DateRange>> = [
  {
    key:'custom',
    title: 'Custom',
    getRange: () => {
      const today = roundDate(new Date());
      return {start: today, end: today}
    }
  },
  {
    key:'today',
    title: 'Today',
    getRange: () => {
      const today = roundDate(new Date());
      return {start: today, end: shiftDay(today, +1)}
    }
  },
  {
    key:'yesterday',
    title: 'Yesterday',
    getRange: () => {
      const date = roundDate(new Date());
      return {start: shiftDay(date, -1), end: date}
    }
  },
  {
    key:'lastweek',
    title: 'Last 7 day',
    getRange: () => {
      const today = roundDate(new Date());
      const start = shiftDay(today, -7);
      return {start: start, end: today}
    }
  },
  {
    key:'lastmonth',
    title: 'Last 28 day',
    getRange: () => {
      const today = roundDate(new Date());
      const start = shiftDay(today, -28);
      return {start: start, end: today}
    }
  },
  {
    key:'quarter',
    title: 'Last 90 day',
    getRange: () => {
      const today = roundDate(new Date());
      const start = shiftDay(today, -90);
      return {start: start, end: today}
    }
  }
]

interface Props {
  dateRange?: DateRange;
  dateType?: DateType;
  onChange: (value: DateRange, dateType?: DateType) => void;
}

const DateRangeTag: React.FC<Props> = ({
    dateRange,
    onChange,
    dateType
  }) => 
{
  const currentDate = roundDate(new Date());
  const [stateRange, setRange] = useState<DateRange>({
                        start: dateRange && dateRange.start ? roundDate(dateRange.start) : currentDate,
                        end: dateRange && dateRange.end ? roundDate(dateRange.end) : currentDate
                      });
  const [rangeType, setRangeType] = useState('custom');
  const handleSelect = (value: DateRange) => {
    setRange(value);
    onChange(value, dateType);
  }

  const onChangeDateType = (value: DateType) => {
    onChange(stateRange, value);
  }

  useEffect( () => {
    const preset = Ranges.find(range => {
      const {start: presetStart, end: presetEnd} = range.getRange();
      const {start, end} = stateRange;
      return start.getTime() === presetStart.getTime()
            && end.getTime() === presetEnd.getTime()
    });
    if (preset) {
      setRangeType(preset.key)
    }
  }, [dateRange?.start, dateRange?.end, stateRange, rangeType, dateType])

  const classes = useStyles();
	return (
    <Box className={classes.boxContent} sx={{flexDirection: {lg: 'row', xs: 'column'}}}>
      <Box className={classes.boxAlignLeft} >
        <RangePreset onSelect={(range: DateRange, type) => {
                            setRangeType(type);
                            handleSelect(range);
                        }}
                    caption='Date range'
                    options={Ranges}
                    typeRange={rangeType}
                    disabledFirst/>
        { dateType && (
                <RangePreset onSelect={(value, type) =>  onChangeDateType(type as DateType)}
                              caption='Date type'
                              options={dateTypes}
                              typeRange={dateType}/>)
        }
      </Box>

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Box className={classes.datePickers} sx={{justifyContent: {lg: 'space-between', xs: 'flex-end'}}}>
          <DatePicker
            label='First at'
            value={stateRange.start}
            onChange={(newDate) => {
                          setRangeType('custom');
                          handleSelect({start: newDate?? new Date(), end: stateRange.end});
                        }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
          <Box className={classes.datePickerSpace} />
          <DatePicker
            label='Last at'
            value={stateRange.end}
            onChange={(newDate) => {
                          setRangeType('custom');
                          handleSelect({start: stateRange.start, end: newDate ?? new Date()})
                        }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
      </Box>
      </LocalizationProvider>
    </Box>
	);
}


export default DateRangeTag;