import { Link } from '@mui/material';
import { Button,
         useRedirect } from 'react-admin';

export type OnLinkClick = (ref: RedirectPath) => void;

export interface RedirectPath {
  filter: Record<string, any>;
  pathTo: string;
  redirectTo: string;
}

interface Props extends RedirectPath{
  label: string;
  onLink?: OnLinkClick;
}

const ButtonLink: React.FC<Props> = (props: Props) => {
  const redirect = useRedirect();
  const {filter, pathTo, redirectTo, label} = props;
  const onClick = () => {
      let path = pathTo;
      if (filter) {
        path += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
      }
      redirect(redirectTo, path);
    };
  return <Button 
            label = {label}
            variant='outlined'
            onClick={onClick}/>
}

export const ResourceLink: React.FC<Props> = (props: Props) => {
  const redirect = useRedirect();
  const {filter, pathTo, redirectTo, label, onLink} = props;
  const onClick = () => {
      let path = pathTo;
      if (filter) {
        path += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
      }
      if (onLink) {
        onLink({
            filter,
            pathTo,
            redirectTo
          });
      }
      redirect(redirectTo, path);

    };
  return <Link underline="hover" onClick={onClick}>{label}</Link>
}


export default ButtonLink;
