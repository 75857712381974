import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';

export interface ColumnDesc {
  label: string;
  id?: string;
  isNumeric? : boolean;
  isPadding?: boolean;
}

interface Props {
  order: 'asc' | 'desc';
  orderBy: string;
  onRequestSort: (event: any, property: string) => void;
  columnsDesc: Array<ColumnDesc>;
}

const SortedTableHead: React.FC<Props> =  props => {
  const {
         order,
         orderBy,
         onRequestSort,
         columnsDesc: headCells } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead sx={{backgroundColor: '#8fcdf2'}}>
      <TableRow>
        {headCells.map((headCell, index) => {
          const isLast = index === headCells.length - 1;
          return (
          <TableCell
            key={headCell.id}
            align={headCell.isNumeric ? 'right' : 'left'}
            padding={!headCell.isPadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={isLast
                ? {width: '25%', maxWidth: '200px', paddingRight: '20px'}
                : {paddingLeft: '10px'}
              }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        )})}
      </TableRow>
    </TableHead>

  );
}

export default SortedTableHead;