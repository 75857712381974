//import PeopleIcon from '@material-ui/icons/People';
import { Resource, ResourceElement } from 'react-admin';
import GroupsCreate from './create';
import GroupsList from './list';
import GroupIcon from '@mui/icons-material/Group';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Пользователи
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const GroupsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - пользователи
  return permissions
              ? <Resource name= {getResourcePath('groups', 'accounts')}
                          list={GroupsList}
                          create={GroupsCreate}
                          icon={GroupIcon}
                          options={{label: "Groups"}} />
              : null;
}


export default GroupsPage;
