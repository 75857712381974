/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material"; 
import AddUser from './add-user';
import { useContext } from 'react';
import UserAtGroupContext from './hooks/store-context';
    

const UsersAtGroup = (props: any) => {
  const {users, deleteUser, addUser, setPage, setPerPage, total, perPage} = useContext(UserAtGroupContext);

  const userColumns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      minWidth: 250,
      sortable: true
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "user-record-action",
      headerName: "Action",
      minWidth: 250,
      width: 100,
      sortable: false,
      renderCell: (params: any) => {
        return (
                <Button 
                    onClick={() => {
                        deleteUser(params.id);
                      }}>
                        Delete
                </Button>
      )}
    }
  ];

  return (
          <>
            <DataGrid
                rows={users}
                columns={userColumns}
                rowCount={total}
                pageSize={perPage}
                onPageChange = {newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => setPerPage(newPageSize)}
                autoHeight/>
            <AddUser excludeUsers={users}
                      record={props.record}
                      onAdd={selected => {
                        if (selected) {
                          addUser(selected)
                        }
                      }}/>
          </>);
}

export default UsersAtGroup;
