/**
 * Реализация отображение запросов на исследование
 */
import React, { cloneElement, useMemo, useState } from 'react';

import {
    Datagrid,
    DateField,
    ExportButton,
    FilterButton,
    List,
    RaRecord,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput, 
    TopToolbar,
    Title,
    useRecordContext} from 'react-admin';

import { Box, Typography } from '@mui/material';
import TextViewField from 'components/text-view';
import SnapShotTextField from 'components/snapshot-text-field';
import ButtonLink from 'components/buttons/link';
import Tablist from 'components/tabs/tab-list';
import StatisticsPanel from './statistics';
import {DateRange as DateRangeInfo} from 'components/date-range/types';
import DateRanges from 'components/date-range/ra-date-range';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';
import { getResourcePath } from 'admin/pages-factory';


type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
      <DateRanges source='date' label='Date'/>,
      <TextInput source="dci" label = 'DCI' autoFocus/>,
      <TextInput source="text" label = 'User Text' autoFocus/>,
      <TextInput source="documentID" label = 'Document ID' autoFocus/>,
  ];
  return retList;
}

const DetailPanel: React.FC<RaProps> = props => {
  const record = useRecordContext();
  return (
    <SimpleShowLayout>
        <TextViewField source="text" />
        <ButtonLink label = 'Open Web Clients'
                    redirectTo = 'list'
                    pathTo = {getResourcePath('/clients')}
                    filter = {{id: record.dci}} />

    </SimpleShowLayout>);
}

const ShowDetail: React.FC<RaProps> = props => {
  return (
    <Show
      {...props}
      /* disable the app title change when shown */
      title=" ">
        <DetailPanel {...props} />
    </Show>);
}

const Empty = () => {
  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
              No Research Available
          </Typography>
      </Box>
  );
};

const ListActions: React.FC<RaProps> = (props) => {
  return (
    <TopToolbar>
        {props.filters && cloneElement(props.filters, { context: 'button' })}
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
  );

}

const ResearchList: React.FC<Props> = ({ permissions, ...props }) => {
  return (
    <>
      <List
            {...props}
            empty={<Empty />}
            title= ' '
            filters={getFilters(permissions)}
            sort={{ field: 'date', order: 'DESC' }}
            perPage={25}
            actions={<ListActions/>}
            bulkActionButtons={false}>
        <Datagrid 
          bulkActionButtons={false}
          expand={<ShowDetail {...props}/>}>
              <DateField source="date" label='Date'/>
              <TextField source="dci" label='DCI'/>
              <SnapShotTextField source="text" label='Text'/>
        </Datagrid>
      </List>
    </>
  );
}

const MainPanel: React.FC<Props> = (props) => {
  const {services} = useStoreContext();
  const [dateRange, setDateRange] = useState<DateRangeInfo | undefined>(undefined);
  const [active, setActiveTab] = React.useState<string>('Statistic');
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Researches`, [services.activeId]);
  const listTab = [
      {
        label: 'Statistic',
        render: <StatisticsPanel dateRange={dateRange} onDateChanged={setDateRange}/>
      },
      {
        label: 'Data',
        render: <ResearchList {...props}/>
      }
    ];

  return ( 
    <Box>
      <Title title={label} />
      <Tablist list={listTab} active={active} onActive={setActiveTab}/>
    </Box> );
}

export default observer(MainPanel);