import { ResourceElement } from "react-admin";
import OcrPdfsPage from "admin/pages/ocr/pdfs";

function buildOcrPages(permissions: any): Array<ResourceElement> {
  const list = [
      OcrPdfsPage(permissions),
   ].filter(item => item != null);
   return list as Array<ResourceElement>;
}

export default buildOcrPages;
