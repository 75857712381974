//import PeopleIcon from '@material-ui/icons/People';
import { Resource, ResourceElement } from 'react-admin';
import List from './list';
import Icon from '@mui/icons-material/VpnKey';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Пользователи
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const ApiKeysPage  = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - пользователи
  return permissions
              ? <Resource name= {getResourcePath('api_keys', 'accounts')}
                          list={List}
                          icon={Icon}
                          options={{label: "API Keys"}} />
              : null;
}


export default ApiKeysPage;
