/**
 * Реализация отображение списка инофрмации по клиентам которые запрашивали загрузку тм-апи продукта
 */
import React, { useMemo } from 'react';
import {
    Datagrid,
    List,
    EditButton,
    BooleanField,
    RaRecord,
    NumberField} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const Empty = () => {
  return (
      <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            Empty Backend Options
          </Typography>
      </Box>
  );
};

const LanguageSupportsPanel: React.FC<Props> = ({ permissions, ...props }) => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Notifications`, [services.activeId]);
  return (
      <List
            {...props}
            empty={<Empty />}
            title={label}
            perPage={25}
            bulkActionButtons={false}>
        <Datagrid
          bulkActionButtons={false} >
            <BooleanField source="ocr_enabled" label = 'OCR enabled' looseValue={false}/>
            <BooleanField source="tokens_limit_enabled" label = 'Tokens Limits Enabled' looseValue={false}/>
            <NumberField source="tokens_limit_current" label = 'Tokens Limits Current'/>
            <NumberField source="tokens_limit_max" label = 'Tokens Limits Max'/>
            {permissions === 'admin' && <EditButton />}
            <EditButton />
        </Datagrid>
      </List>
  );
}


export default LanguageSupportsPanel;