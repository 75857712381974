import InvoicesPage from "admin/pages/accounts/invoices";
import SubscriptionsPage from "admin/pages/accounts/subscriptions";
import AccessTokensPage from "admin/pages/accounts/access-tokens";
import ApiKeysPage from "admin/pages/accounts/api-keys";
import GroupsPage from "admin/pages/groups";
import PermissionsPage from "admin/pages/permissions";
import UsersPage from "admin/pages/users";
import { ResourceElement } from "react-admin";


function buildAccountsPages(permissions: any): Array<ResourceElement> {
  const list = [
    UsersPage(permissions),
    GroupsPage(permissions),
    PermissionsPage(permissions),
    SubscriptionsPage(permissions),
    InvoicesPage(permissions),
    AccessTokensPage(permissions),
    ApiKeysPage(permissions)
  ].filter(item => item != null);

  return list as Array<ResourceElement>;
}

export default buildAccountsPages;
