import axios, { AxiosInstance } from "axios";
import { jwtManager } from "./auth";
import JwtManager from "./jwt-manager";

function createApiConnection(jwt: JwtManager) {
  const instance = axios.create({maxRedirects: 0});
  instance.defaults.withCredentials = true;
  instance.interceptors.request.use(async (request) => {
    try {
        request.headers.set('Accept', 'application/json')
        if (jwt.token) {
          request.headers['Authorization'] = `Bearer ${jwtManager.token}`;
        }
        return request;
    }
    catch (err) {
      throw new Error(`axios# Problem with request during pre-flight phase: ${err}.`);
    }
  });
  return instance;
}

const apiRequestProvider: AxiosInstance = createApiConnection(jwtManager);

export default apiRequestProvider;