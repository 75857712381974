import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getComparator, stableSort } from 'utilities/algorithm/sort';
import LinearProgressWithLabel from 'components/progress/linear-with-label';
import { OnLinkClick, RedirectPath, ResourceLink } from 'components/buttons/link';
import SortedTableHead, { ColumnDesc } from './sorted-table-head';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '10px',
    width: '100%'
  }
}));

export interface CountPerField {
  id: string;
  count: number;
}


export type LinkGenerator = (id: string) => RedirectPath;

interface Props {
  sortBy: string;
  preColumn?: (id: string, index: number, children: React.ReactNode) => React.ReactNode;
  linkGenerator?: LinkGenerator;
  heads: Array<ColumnDesc>;
  resource: Array<CountPerField>;
  idLabelKey?: string;
  onLink?: OnLinkClick;
}

const DataTable: React.FC<Props> = props => {
  const common = props.resource.reduce((acc, item) => item.count + acc, 0);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState(props.sortBy);
  const [resource] = React.useState(
          props.resource.map(
              ({count, ...other}) => ({...other, count, precentage: common ? (count * 100 / common): 0})));
  const handleRequestSort = (event: any, property: string) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  const classes = useStyles();
  const idLabelKey = props.idLabelKey ? props.idLabelKey : 'id';
  return (
      <TableContainer component={Paper} className={classes.content}>
        <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
          <SortedTableHead
                order={order}
                orderBy={orderBy}
                columnsDesc={props.heads}
                onRequestSort={handleRequestSort}/>

          <TableBody>
            {stableSort(resource, getComparator(order, orderBy))
              .map( (row, index) => {
                const precentage: number = parseFloat(row.precentage.toFixed(2));
                const label = row[idLabelKey] ? row[idLabelKey] :'< Unknown >';
                const valueField = props.linkGenerator
                                    ? <ResourceLink  label={label} {...props.linkGenerator(row.id)} onLink={props.onLink}/>
                                    : label;
                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {props.preColumn 
                        ? props.preColumn(row.id, index, valueField)
                        : (
                            <TableCell align="left" component="th" scope="row">
                              {valueField}
                            </TableCell>)}
                    <TableCell align="right">{row.count}</TableCell>
                    <TableCell align="right">
                      <LinearProgressWithLabel value={precentage} />
                    </TableCell>
                  </TableRow>)
                })}
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default DataTable;