/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    RaRecord,
    Show,
    TextField,
    TextInput} from 'react-admin';
import DateRanges from 'components/date-range/ra-date-range';
import PropertiesPanel from './properties-panel';

const ShowDetail: React.FC = (props: any) => {
  return (
    <Show
      /* disable the app title change when shown */
      {...props}
      title=" ">
        <PropertiesPanel {...props}/>
    </Show>);
}


const getFilters = (permissions: string): React.ReactElement[] => {
  const retList: React.ReactElement[] = 
  [
    <TextInput source="user_name" label='User Name' autoFocus/>,
    <TextInput source="plan_name" label='Plan Name' autoFocus/>,
    // <DateRanges source="start" label = 'Start' />,
    // <DateRanges source="end" label = 'End' />,
    <NumberInput source="page_count" label='Page Count' autoFocus/>,
    <NumberInput source="page_count_max" label='Page Count Max' autoFocus/>,
  ];
  return retList;
}

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}
  
const SubscriptionsList: React.FC<Props> = ({ permissions, ...props }) => {
  return <List
                title="Subscriptions"
                filters={getFilters(permissions)}
                perPage={25}
                sort={{ field: 'start', order: 'DESC' }}
                bulkActionButtons={false}
                {...props}
                >
            <Datagrid 
                  bulkActionButtons={false}
                  expand={<ShowDetail />}
                  expandSingle>
                <TextField source="user_name" label='User Name'/>
                <TextField source="plan_name" label='Plan Name'/>
                <DateField source="start" label = 'Start'/>
                <DateField source="end" label = 'End'/>
                <NumberField source="page_count" label='Page Count'/>
                <NumberField source="page_count_max" label='Page Count Max'/>
            </Datagrid>
          </List>
}

export default SubscriptionsList;
