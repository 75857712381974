/**
 * Реализация отображение списка загруженных pdf файлов в сервисе OCR
 */

import React, { useMemo } from 'react';

import {
    Datagrid,
    DateField,
    List,
    NumberField,
    NumberInput,
    RaRecord,
    TextField, 
    TextInput} from 'react-admin';
import { observer } from 'mobx-react-lite';
import { capitalizeFirstLetter } from 'utilities/algorithm/string';
import { useStoreContext } from 'admin/store-context';
import DateRanges from 'components/date-range/ra-date-range';

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

// Фильтрация элементов по колонкам
const getFilters = (permissions: string): React.ReactElement[] => {
    const retList: React.ReactElement[] = 
    [
      <NumberInput source="id" label='ID' autoFocus/>,
      <TextInput source="user.name" label='User Name' autoFocus/>,
      <TextInput source="user.email" label='User Email' autoFocus/>,
      <TextInput source="file_name" label='File Name' autoFocus/>,
      <NumberInput source="pages" label='Pages' autoFocus/>,
      <TextInput source="error" label='Error'/>,
      <TextInput source="processing_time" label='Processing Time (sec)'/>,
      <DateRanges source="created_at" label='Created At'/>

    ];
    return retList;
}

const PDFsList: React.FC<Props> = ({ permissions, ...props }) => {
  const {services} = useStoreContext();
  const label = useMemo(()=>`${capitalizeFirstLetter(services.activeId??'')}/Pdfs List`, [services.activeId]);
  
   return (
    <>
     <List
        {...props}
        title={label}
        filters={getFilters(permissions)}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}>
          <Datagrid 
              bulkActionButtons={false}>
                <NumberField source="id" label='ID'/>
                <TextField source="user.name" label='User Name'/>
                <TextField source="user.email" label='User Email'/>
                <TextField source="file_name" label='File Name'/>
                <NumberField source="pages" label='Pages'/>
                <TextField source="error" label='Error'/>
                <TextField source="processing_time" label='Processing Time (sec)'/>
                <DateField source="created_at" label='Created At' showTime={true}/>
          </Datagrid>
    </List>
    </>
    )
};

export default observer(PDFsList);
