import { Resource, ResourceElement } from 'react-admin';
import List from './list';
import Icon from '@mui/icons-material/ThumbUpAlt';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Отзывы (translate-website)
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const TranslateFeedbacksPage  = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - Отзывы (translate-website)
  return permissions
              ? <Resource name= {getResourcePath('translation_feedbacks', 'translate')}
                          list={List}
                          icon={Icon}
                          options={{label: "Feedbacks"}} />
              : null;
}


export default TranslateFeedbacksPage;
