import { Resource, ResourceElement } from 'react-admin';
import WebClients from './web-clients-list';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Браузер-клиенты
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const WebClientsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - клиенты
  return permissions
              ? <Resource name= {getResourcePath('clients')}
                          list={WebClients}
                          icon={EmojiPeopleIcon}
                          options={{label: "Clients"}} />
              : null;
}


export default WebClientsPage;
