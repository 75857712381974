import { useState, useCallback, useEffect } from 'react';
import {
    Identifier,
    useDataProvider, 
    useNotify} from 'react-admin';

import { emptyParamList, Permission } from 'admin/pages/types';
import { getResourcePath } from 'admin/pages-factory';

type Permissions = Array<Permission>;
let defaultInitPerPage: number = 100;


interface Props {
  defaultPermissions: Permissions;
  perPage: number;
}

interface PermAtGroupsProps extends Partial<Props> {
  groupId: Identifier
}

function usePermissionsAtGroups(props: PermAtGroupsProps) {
  const {defaultPermissions=[], perPage: initPerPage = defaultInitPerPage, groupId} = props;
  const [permissions, setPermissions] = useState<Permissions>(defaultPermissions);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initPerPage)
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updatePermissions = useCallback (() => {
      setLoading(true);
      return dataProvider.getList(getResourcePath('groups', 'accounts'), {...params,  meta:{rootID: groupId, subName: 'permissions'} })
              .then(async (response) => {
                setTotal(response.total ?? 0)
                await setPermissions(response.data);
                return response.data;
              })
              .finally(() => {
                setLoading(false);
              })
    }, [dataProvider, groupId, page, perPage]);

  const deletePermission = (idUser: number) => {
      setLoading(true);
      dataProvider.delete(getResourcePath('groups', 'accounts'), { meta:{rootID: groupId, subName: 'permissions'}, id: idUser })
        .then(result => {
          updatePermissions();
        });
    };

  const addPermission = (data: Permission) => {
      setLoading(true);
      dataProvider.create(getResourcePath('groups', 'accounts'), { data: data, meta:{rootID: groupId, subName: 'permissions'}})
        .then(() => {
          updatePermissions();
        });
  };

  useEffect(() => {
      updatePermissions();
    }, [updatePermissions]);
        
  return {permissions, updatePermissions, loading, deletePermission, addPermission, page, perPage, total, setPage, setPerPage};
}

interface PermissionProps extends Partial<Props> {
  excluded: Permissions
}

export function usePermission(props: PermissionProps) {
  const {excluded=[], defaultPermissions = [], perPage: initPerPage = defaultInitPerPage} = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [permissions, setPermissions] = useState<Permissions>(defaultPermissions);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initPerPage)
  const [total, setTotal] = useState(0);
  const params = {...emptyParamList};
  params.pagination = {perPage, page};
  const updatePermissions = useCallback((isOk: () => void) => {
    setLoading(true)
    dataProvider.getList(getResourcePath('permissions', 'accounts'), {...params })
    .then( response => {
        setTotal(response.total ?? 0)
        const filtered = response.data.filter(item => !excluded.some(exclude => exclude.id === item.id))
        setPermissions(filtered);
        isOk();
    })
    .catch(error => {
        notify(`Query permissions list error: ${error.message}`, {type: 'error'});
    })
    .finally(() => {
      setLoading(false);
    })
  }, [dataProvider, page, perPage, excluded])
  return {permissions, loading, page, perPage, total, setPage, setPerPage, updatePermissions};
}

export default usePermissionsAtGroups;
