import { Resource, ResourceElement } from 'react-admin';
import Researches from './list';
import BiotechIcon from '@mui/icons-material/Biotech';
import { getResourcePath } from 'admin/pages-factory';


/**
 * Компонент страницы - объект исследования (информация об инициаторе исследование)
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const ResearchesPage = (permissions: any): ResourceElement | null => {
  return permissions
              ? <Resource name={getResourcePath('researches')}
                          list={Researches}
                          icon={BiotechIcon}
                          options={{label: "Researches"}} />
              : null;
}


export default ResearchesPage;
