/**
 * Реализация отображение списка пользователей
 * $TODO - на текущей итерации, заглушка (которая что-то там показывает)
 */

import React, { useEffect, useState } from 'react';
import {
  BooleanField,
    DateField,
    FunctionField,
    List,
    RaRecord,
    RecordContextProvider,
    Show,
    SimpleShowLayout,
    TextField,
    useListContext,
    useRecordContext } from 'react-admin';
import UserGroups from './groups-datagrid';
import useGroupsForUser from './hooks/groups';
import Datagrid from 'components/ra-data-grid';
import UserProperties from './user-properties';
import { BooleanOppositeField } from 'components/fields/boolean-opposite-field';
import Loading from 'components/loading';

const DetailPanel = (props: any) => {
  const {record} = props;

  const {groups, loading} = useGroupsForUser({userId: record ? record.id: null});

  if (loading) return <Loading />;
  if (!groups || !record) return null;

  return (
    <>
        <UserProperties {...props}/>
        <UserGroups {...props}/>
    </>
  )
}

const ShowDetail: React.FC = (props: any) => {
  return (
    <Show
      /* disable the app title change when shown */
      {...props}
      title=" ">
        <DetailPanel {...props}/>
    </Show>);
}

const UserList: React.FC = () => {
  const { data, isLoading } = useListContext();
  return <List
                title="List Of Users"
                perPage={25}
                bulkActionButtons={false}>
            <Datagrid 
                  bulkActionButtons={false}
                  expand={<ShowDetail />}
                  expandSingle>
                <TextField source="username" label='User Name'/>
                <TextField source="email" label='Email'/>
                <BooleanField source="verified_email" label='Verified'/>
                <BooleanOppositeField source="deleted" label='Enabled'/>
                <DateField source="created_at" label = 'Create At'/>
            </Datagrid>
          </List>
}

export default UserList;
