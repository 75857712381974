import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Title } from "react-admin";

interface TabPanelProps {
  active: boolean;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (props) => {
  const { children, active} = props;

  return (
    <div
      hidden={!active}
    >
      {active && (
        <Box>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabItem {
  key?: string;
  label: string;
  render: React.ReactNode;
}

interface Props {
  label?: string;
  list: Array<TabItem>;
  active: string;
  onActive: (active: string) => void;
}

const TabList = ({list, active, onActive, label}: Props) => {
  const getKey = (item: TabItem) => item.key ? item.key: item.label;
  const getIndex = (key: string) => list.findIndex(item => item.key ? item.key === key : item.label === key)

  const handleChange = (event: React.SyntheticEvent, activeIndex: any) => {
    onActive(getKey(list[activeIndex]))
  };

  const tabs = list.map(item => {
      return (<Tab key={getKey(item)} label={item.label} />);
    });
  const panels = list.map(item => {
      const key = getKey(item);
      return (<TabPanel key={key} active={key === active}>
                {item.render}
              </TabPanel>);
    });
  return (
    <Box >
      {label && <Title title={label} />}
      <Box >
        <Tabs value={getIndex(active)} onChange={handleChange}>
          {tabs}
        </Tabs>
      </Box>
      {panels}
    </Box>
  );
}

export default TabList;
