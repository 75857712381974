import {
    SimpleShowLayout,
    DateField,
    ShowController,
    ShowView,
    RaRecord} from 'react-admin';
import NotificationTest from "./test";

type RaProps = Omit<RaRecord, 'id'>;

interface Props extends RaProps {
  permission: string;
}

const NotificationShow: React.FC<Props> = (props) => {
  return <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps}>
        <SimpleShowLayout>
          <DateField source="createdAt" label='Created At'/>
          {controllerProps.record && controllerProps.record.modifiedAt && <DateField source="modifiedAt" label='Modified At'/>}
          {controllerProps.record && controllerProps.record.activateAt && <DateField source="activateAt" label='Activate At'/>}
          {controllerProps.record && controllerProps.record.lastSuccessSendAt && <DateField source="lastSuccessSendAt" label='Last Success Send At'/>}
          <NotificationTest id={props.id}/>
        </SimpleShowLayout>
      </ShowView>
    }
  </ShowController>
};

export default NotificationShow;
