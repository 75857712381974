//import PeopleIcon from '@material-ui/icons/People';
import { Resource, ResourceElement } from 'react-admin';
import PermissionsList from './list';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - права доступа
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const PermissionsPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку) - права доступа
  return permissions
              ? <Resource name= {getResourcePath('permissions', 'accounts')}
                          list={PermissionsList}
                          icon={DesktopAccessDisabledIcon}
                          options={{label: "Permissions"}} />
              : null;
}


export default PermissionsPage;
