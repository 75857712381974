import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface Props {
  value: number;
}

const LinearProgressWithLabel: React.FC<Props> = props => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
      <Box sx={{ width: '70%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ height: '10px'}}/>
      </Box>
      <Box sx={{ minWidth: '50px' }}>
        <Typography variant="body2" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;