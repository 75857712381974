import {
  RaRecord,
  useListContext, 
  useRecordContext } from 'react-admin';
import { extractFragment, highlightFilteredText } from 'components/text-utils';

// Максимальная длина текста при отображении в колонке (превью)
const MAX_PREVIEW_TEXT_LEN = 200;
// Коэффициент сдвига текста при вычислении фрагмента. 
// 0 - фрагмент будет начинаться с текста по которому делали поиск
// 0.5 - искомый текст будет находиться примерно в середине текста
// 1 - искомый текст будет завершать фрагмент
const SHIFT_FACTOR = 0.3;


interface Props extends Omit<RaRecord, 'id'>{
  source: string;
  maxPrevLen?: number;
  shiftFactor?: number;
  minWidth?: number;
}
// Компонент отображения превью текста
const SnapShotTextField: React.FC<Props>= props => {
  
  const { filterValues } = useListContext();
  const record = useRecordContext(props);
  const originalText: string = record[props.source];
  const {maxPrevLen = MAX_PREVIEW_TEXT_LEN, shiftFactor = SHIFT_FACTOR} = props;
  const filter = filterValues && props.source ? filterValues[props.source] : null;
  const fragment = extractFragment(originalText, filter, maxPrevLen, shiftFactor);
  const style: React.CSSProperties = {minWidth: props.minWidth};
  return (<span key={`${props.source}+${record.id}`} style={style}>
            {highlightFilteredText(fragment, filter)}
          </span>)
}

export default SnapShotTextField;