import { Resource, ResourceElement } from 'react-admin';
import list from './messages-list';
import Icon from '@mui/icons-material/Forum';
import { getResourcePath } from 'admin/pages-factory';

/**
 * Компонент страницы - Список загруженных файлов в истории сервиса сравнения
 * @param permissions - полученные права доступа
 * @returns - React.Node
 */
 const CompareHistoryPage = (permissions: any): ResourceElement | null => {
  // в упрощенном варианте - пока есть права, рендерим страницу(вкладку)
  return permissions
              ? <Resource name= {getResourcePath('gpt_chat_messages', 'gpt')}
                          list={list}
                          icon={Icon}
                          options={{label: "Messages"}} />
              : null;
}


export default CompareHistoryPage;
